import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['tab']

  open (event) {
    this.tabTargets.forEach((tab, index) => {
      tab.classList.remove('active')
    })
    event.currentTarget.classList.add('active')
  }
};
