import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ['field', 'resetSection']
  static values = { updated: String, current: String}

  initialize () {}

  resetSectionTargetConnected(section) {
    this.fieldTarget.addEventListener('input', () => {
      if (this.fieldTarget.value !== this.currentValue) {
          section.classList.remove('hidden')
      }
      if (this.fieldTarget.value === this.currentValue) {
          section.classList.add('hidden')
      }
    })
  }

  updateValue(event){
    const changed = this.fieldTarget.value !== this.updatedValue

    this.fieldTarget.value = this.updatedValue

    if(changed)
      this.fieldTarget.dispatchEvent(new Event('input', { bubbles: true }))
  }

  reset(event){
    const changed = this.fieldTarget.value !== this.currentValue

    this.fieldTarget.value = this.currentValue
    // this.resetSectionTarget.classList.toggle('hidden')

    if(changed)
      this.fieldTarget.dispatchEvent(new Event('input', { bubbles: true }))
  }
};
