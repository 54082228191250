import { Controller } from 'stimulus';
import TurboFrameSearch from '../src/turbo_frame_search'
import {debounce} from "lodash";

export default class extends Controller {
  static targets = ['peoSelector', 'results'];
  static values = {url: String}
  engine: TurboFrameSearch


  initialize(){
    this.parameters = {}

    if (this.hasPeoSelectorTarget)
      this.parameters['peo'] = () => this.peoSelectorTarget

    this.engine = new TurboFrameSearch(
      this.parameters,
      () => this.resultsTarget,
      this.urlValue
    )
  }

  search = debounce(() => {
    this.engine.search()
  }, 200)
};
