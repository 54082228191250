window.Tablesort = require('tablesort')
require('tablesort/src/sorts/tablesort.number')
require('tablesort/src/sorts/tablesort.date')

import {Controller} from 'stimulus'

export default class extends Controller {
  initialize () {
    this.tablesort = new Tablesort(this.element, {})
  }
};
