import {Controller} from 'stimulus'

export default class extends Controller {
  connect () {
    this.element.addEventListener('click', () => {this.showSpinner()})
  }

  showSpinner () {
    this.element.innerHTML =
      '<svg class=\'animate-spin\' height=\'17\' width=\'17\'>' +
      '<use xlink:href=\'#ico-animate-spin\'/>' +
      '</svg>'
  }

}
