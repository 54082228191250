require('slim-select')
import 'slim-select/styles'
import {Controller} from 'stimulus'
import SlimSelect from 'slim-select'

export default class extends Controller {
  static values = {
    placeholder: String,
    closeOnSelect: Boolean
  }

  connect () {
    this.slimselect = new SlimSelect({
      select: this.element,
      settings: {
        closeOnSelect: this.hasCloseOnSelectValue ? this.closeOnSelectValue : false,
        maxValuesShown: 100,
        placeholderText: this.hasPlaceholderValue ? this.placeholderValue : null
      }
    })
  }

  disconnect () {
    this.slimselect.destroy()
  }
}
