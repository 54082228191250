import {BaseUrlAction} from "./base_url_action_controller";
// TODO: This is a legacy controller and should be updated to the new version
export default class extends BaseUrlAction {
  static get targets () {
    return ['tab']
  }

  initialize() {
    window.addEventListener("resize", this.resize.bind(this));
  }

  urlAction(target) {
    this.open(target)
  }

  get height() {
    return this.element.offsetHeight - (62*4)
  }

  resize(event) {
    for(const tab of this.tabTargets) {
      const row = tab.closest('.modal__row')
      if(row.classList.contains('is-active')) {
        const height = this.height
        tab.nextElementSibling.style.height = height + 'px'
      }
    }
  }

  toggle (event) {
    let trigger = this.tabTargets.find(e => e === event.currentTarget)

    this.close(event.currentTarget)
    this.open(trigger)
  }

  open (trigger) {
    const row = trigger.closest('.modal__row')
    const tab = trigger.nextElementSibling

    if(!row.classList.contains('is-active')){
      tab.style.height = this.height + 'px'
      tab.style.display = 'block'
      row.classList.add('is-active')
    }else(
      this.closeOne(trigger)
    )
  }

  dontOpen (event) {
    event.stopImmediatePropagation()
  }

  closeOne (trigger) {
    const row = trigger.closest('.modal__row')
    const tab = trigger.nextElementSibling

    tab.style.height = '0'
    row.classList.remove('is-active')
  }

  close (trigger) {
    const _this = this
    this.tabTargets.filter(e => e !== trigger).forEach(function(elem){
      _this.closeOne(elem)
    })
  }
}
