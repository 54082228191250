import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['layout']

  initialize() {
    try {
      this.setSidebarClass()
    } catch (error) {}
  }

  toggleFullScreenMode() {
    window.sessionStorage.setItem('fullScreenMode', this.fullScreenMode === 'true' ? 'false' : 'true')
    this.setSidebarClass()
  }

  setSidebarClass() {
    if (!this.hasLayoutTarget) { return }

    this.pullFullScreenMode()
    if (this.fullScreenMode === 'true') {
      $(this.layoutTarget).addClass('full-screen-mode')
    } else {
      $(this.layoutTarget).removeClass('full-screen-mode')
    }
  }

  pullFullScreenMode() {
    this.fullScreenMode = window.sessionStorage.getItem('fullScreenMode') || this.defaultFullScreenMode()
  }

  defaultFullScreenMode() {
    return window.screen.width < 640 ? 'true' : 'false'
  }
}
