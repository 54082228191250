import { Controller } from 'stimulus';
export default class extends Controller {
  static get targets () {
    return ["form", "submitButton", "checkbox", "deleteCheckbox"]
  }

  submit (event) {
    this.submitButtonTarget.click();
  }

  deactivate (event) {
    $.each(this.checkboxTargets, function(_idx, el) {
      $(el).prop("checked", false);
    });
  }

  destroy(event){
    if(this.confirmDelete(event)){
      $(this.deleteCheckboxTarget).prop('checked', true)
    }
  }

  showProgress(element){
    element.innerHTML =
      '<svg class=\'animate-spin\' height=\'17\' width=\'17\'>' +
      '<use xlink:href=\'#ico-animate-spin\'/>' +
      '</svg>'
  }

  confirmDelete(event){
    if(confirm('Are you sure?')){
      this.showProgress(event.currentTarget);
      return true;
    } else {
      event.preventDefault()
      return false;
    }
  }
};
