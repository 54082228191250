import Handsontable from 'handsontable/dist/handsontable.min.js'
import * as Settings from './settings'
import {HandsontableDependents} from './handsontable_dependents'

export default class extends HandsontableDependents {
  initialize () {
    this.ht = new Handsontable(this.censusTarget, Settings.dependentEmployee(this, {}))
  }

  get resourcesUrl () {
    return `/census/opportunities/${this.opportunityIdValue}/dependent_employees`
  }

  get nextStepUrl () {
    return `/opportunities/${this.opportunityIdValue}/census/cobra`
  }
};
