import { BaseUrlAction } from "./base_url_action_controller";

export default class extends BaseUrlAction {
  // target tab
  static get targets () {
    return ['tab']
  }
  connect() {
    this.tabTargets.forEach(tab => tab.addEventListener("click", this.handleToggle.bind(this, tab)));
  }

  urlAction(target) {
    this.handleToggle(target)
  }

  hideAll() {
    this.tabTargets.forEach(tab => {
      tab.classList.remove("is-active");
      tab.nextElementSibling.classList.remove("is-active");
    });
  }

  toggle(element) {
    element.classList.toggle("is-active");
    element.nextElementSibling.classList.toggle("is-active");
  }

  handleToggle(tab) {
    if(!tab.classList.contains("is-active"))
      this.hideAll()
    this.toggle(tab)
  }
}

