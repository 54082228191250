import {Controller} from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['button']
  }

  updateDownloadButton(event) {
    this.buttonTarget.href = this.buttonTarget.dataset.url + '?filter=' + event.target.value;
  }
};
