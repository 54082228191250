import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['password', 'toggle', 'info']

  connect() {
    this.infoTarget.hidden = true
  }

  toggle() {
    const passwordTarget = this.passwordTarget
    const buttonTarget = this.toggleTarget

    if (passwordTarget.type === 'password') {
      passwordTarget.type = 'text'
      buttonTarget.text = 'Hide password'
    } else {
      passwordTarget.type = 'password'
      buttonTarget.text = 'Show password'
    }
  }

  async copy() {
    await navigator.clipboard.writeText(this.passwordTarget.value)
    this.infoTarget.hidden = false
    setTimeout(() => {
      this.infoTarget.hidden = true
    }, 1000)
  }
}
