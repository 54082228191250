import serializedForms from '../admin/serialized_forms'
import {BaseUrlAction} from './base_url_action_controller'

export default class extends BaseUrlAction {
  static values = {
    modalId: String,
    modalUrl: String,
    turboFrameId: String,
    modalStackPosition: {type: Number, default: 0},
  }

  connect() {
    this.element.classList.add('modal-connected')

    if (!this.modalIdValue) {
      this.modalOpen()
    }
  }

  urlAction(target) {
    (this.hasTurboFrameIdValue && this.hasModalUrlValue) ? this.modalOpenAndLoad() : this.modalOpen()
  }

  disconnect() {
    this.modalClose()
  }

  modalOpen() {
    $(this.modalContainer).addClass('is-open')
    this.modalStackPositionValue = document.getElementsByClassName('is-open').length
  }

  modalOpenAndLoad() {
    const frame = document.getElementById(this.turboFrameIdValue)
    frame.innerHTML = ''
    let url = new URL(this.modalUrlValue, window.location)
    let params = new URLSearchParams(url.search)
    params.set('refresh', Date.now())
    url.search = params
    frame.src = url.toString()
    this.modalOpen()
  }

  modalClose() {
    $(this.modalContainer).removeClass('is-open')
    this.modalStackPositionValue = 0
    let form = $(this.modalContainer).find('form').get(0)
    if (form) {
      form.reset()
      serializedForms.reset(form)
    }
  }

  close() {
    if ($(this.modalContainer).hasClass('is-open')) {
      let form = $(this.modalContainer).find('form').get(0)

      if (serializedForms.handleForm(form)) {
        this.modalClose()
      }
    }
  }

  get modalContainer() {
    return this.modalIdValue ? this.modalIdValue : this.element
  }
}
