import SlimSelect from 'slim-select'
import 'slim-select/styles'
import serializedForms from '../admin/serialized_forms'

import { Controller } from 'stimulus'

require('slim-select')

export default class extends Controller {
  static values = {
    selection: [],
    stayOpenAfterSelect: Boolean,
    placeholderText: String
  }

  connect() {
    this.counter = 0

    this.slimselect = new SlimSelect({
      select: this.element,
      settings: {
        closeOnSelect: !this.stayOpenAfterSelectValue,
        keepOrder: true,
        placeholderText: this.placeholderTextValue || 'Select Value'
      },
      events: {
        afterClose: () => {
          this.dispatch('slimModified')
        },
        afterChange: selection => {
          if (selection.length < this.counter) {
            this.dispatch('slimModified')
          }

          this.element.dispatchEvent(new InputEvent('input', { bubbles: true }))
          this.counter = selection.length
        }
      }
    })

    this.element.form.addEventListener('reset', this.reset.bind(this))
    this.reset()
  }

  reset() {
    this.slimselect.setSelected(this.selectionValue, false)
    this.dispatch('slimModified')
    serializedForms.reset(this.element.form)
  }

  disconnect() {
    this.slimselect.destroy()
  }
}
