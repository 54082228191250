import {Controller} from 'stimulus'
import Inputmask from "inputmask";

export default class extends Controller {
  static targets = ['input']
  static values = {extension: String}

  connect() {
    const input = this.inputTarget

    Inputmask(
      {
        mask: `*{+|*}.${this.extensionValue}`,
        placeholder: "",
        rightAlign: false,
        definitions: {
          "*": {
            validator: '[^.]' // any char except dot
          }
        }
      },
    ).mask(input)
  }
}
