import {HandsontableBase} from './handsontable_base'
import Handsontable from 'handsontable/dist/handsontable.min.js'
import * as Settings from './settings'

export default class extends HandsontableBase {
  initializeWithData (data) {
    this.ht = new Handsontable(this.censusTarget, this.updatedConfigForUserPortal(data['questionnaire_entities']))
  }

  get resourcesUrl () {
    return `/census/opportunities/${this.opportunityIdValue}/work_employees`
  }

  get nextStepUrl () {
    return `/opportunities/${this.opportunityIdValue}/census/dependents`
  }

  updatedConfigForUserPortal(questionnaire_entities){
    let settings = Settings.workEmployee(this, undefined, questionnaire_entities)
    settings.columns = settings.columns.map((e) => {
      if (e.data === 'full_name') {delete(e.renderer)}
      return e
    })

    return settings
  }
};
