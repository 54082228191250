import {Controller} from 'stimulus'
import * as Utils from './utils'

import _size from 'lodash/size'
import _keys from 'lodash/keys'
import _forEach from 'lodash/forEach'
import _omit from 'lodash/omit'
import _map from 'lodash/map'
import _values from 'lodash/values'
import _sum from 'lodash/sum'
import _indexOf from 'lodash/indexOf'

export class HandsontableErrors extends Controller {
  static targets = ['error', 'errorMessage', 'errorNavigationButtons', 'errorNavigationButton', 'nextStepButton', 'forceNextStepButton']
  errorsByMessage = {}
  allErrors = {}
  errorsCount = 0
  currentErrorPointer = 0

  loadErrors (data) {
    this.currentErrorPointer = 0
    this.includeMissingContent = false
    this.allErrors = Utils.groupErrorsByMessage(data.errors)

    this.showErrors()
  }

  previousIssue () {
    const len = this.errorMessages.length
    this.currentErrorPointer =
      (--this.currentErrorPointer % len + len) % len
    this.showErrors()
    this.selectNearestCellWithError()
  }

  nextIssue () {
    this.currentErrorPointer = ++this.currentErrorPointer % this.errorMessages.length
    this.showErrors()
    this.selectNearestCellWithError()
  }

  showErrors () {
    this.setErrorsForDisplay()
    this.hideAndClearErrorNavigation()
    if (this.errorsCount > 0) {
      this.showForceNextStepButton()
      this.showErrorBar()
      this.showErrorsOnSpreadsheet()
      this.setErrorMessageHTML()
      this.showErrorNavigationButtons()
    }
  }

  setErrorsForDisplay(){
    if (this.includeMissingContent) {
      this.errorsByMessage = this.allErrors
    } else {
      this.errorsByMessage = _omit(this.allErrors, ['Missing content'])
    }

    this.errorMessages = _keys(this.errorsByMessage)
    this.errorsCount = _sum(_map(_values(this.errorsByMessage), (v) => {return v.length}))
  }

  showErrorBar(){
    $(this.errorTarget).show()
  }

  showForceNextStepButton(){
    if(this.hasForceNextStepButtonTarget) {
      this.forceNextStepButtonTarget.classList.remove('!hidden')
    }
    if (this.hasNextStepButtonTarget) {
      this.nextStepButtonTarget.classList.add('!hidden')
    }
  }

  hideForceNextStepButton(){
    if(this.hasForceNextStepButtonTarget) {
      this.forceNextStepButtonTarget.classList.add('!hidden')
    }
    if (this.hasNextStepButtonTarget) {
      this.nextStepButtonTarget.classList.remove('!hidden')
    }
  }

  showErrorsOnSpreadsheet(){
    _forEach(this.errorsByMessage[this.currentErrorMessage], (cell) => {
      $(this.ht.getCell(
        _indexOf(this.ht.getDataAtProp('id'), parseInt(cell.id)),
        this.ht.propToCol(cell.field))).addClass('ht-error')
    })
  }

  hideAndClearErrorNavigation(){
    $(this.errorTarget).hide()
    $(this.errorNavigationButtonsTarget).hide()
    $(this.errorNavigationButtonTarget).hide()
    this.errorMessageTarget.innerHTML = ''
    this.hideForceNextStepButton()
    $(this.censusTarget).find('td').removeClass('ht-error')
  }

  showErrorNavigationButtons(){
    if (_size(this.errorsByMessage) > 1) {
      $(this.errorNavigationButtonsTarget).show()
    }
    if (_size(this.errorsByMessage) === 1) {
      $(this.errorNavigationButtonTarget).show()
    }
  }

  setErrorMessageHTML(){
    this.errorMessageTarget.innerHTML = `<b>${this.currentErrorMessage} in ${this.errorsByMessage[this.currentErrorMessage].length} cell(s).</b>
      Please correct highlighted cells before submitting Census.`
  }

  selectNearestCellWithError(){
    let cell = this.errorsByMessage[this.currentErrorMessage][0]
    if(cell !== undefined){
      this.ht.selectCell(cell.row, this.ht.propToCol(cell.field))
    }
  }

  get currentErrorMessage () {
    return this.errorMessages[this.currentErrorPointer]
  }
}
