import Rails from '@rails/ujs';

Rails.start();
import "@rails/activestorage"
import "@rails/actiontext"
import "@hotwired/turbo-rails"
import "chartkick/chart.js"

global.$ = require("jquery")

require('jquery')
require('jquery-ui-dist/jquery-ui')

import "channels"
import 'controllers'
import "materialize-css"
import '../admin'
import "../src/app"
import "trix"

$(window).bind('turbo:before-fetch-response', function (event) {
  if (['/users/sign_in', '/otp'].includes(window.location.pathname)) {
    // this hack makes sure the after log in the user will be redirected to the page where he left off
    window.location = window.location.href
  }
})

// this event is triggered when using Back button with history.pushState used in search_controller.js
// https://developer.mozilla.org/en-US/docs/Web/API/History_API/Working_with_the_History_API
$(window).bind('popstate', function(event){
  window.location = location.href
});

require("trix")
require("@rails/actiontext")
