import { Controller } from 'stimulus';
export default class extends Controller {
  connect() {
    this.element.addEventListener('change', this.submitOnSelect.bind(this));
  }

  submitOnSelect(event) {
    const form = this.element.form
    if(form)
      form.requestSubmit();
  }
}
