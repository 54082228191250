import {Controller} from 'stimulus'
import Tablesort from 'tablesort'

export default class extends Controller {
  static values = {
    extension: String,
    id: String,
  }

  initialize() {
    const extensionFunctionName = `${this.extensionValue}${this.idValue}`

    const extensions = {
      slimSelectOrder: {
        pattern: this.isNumeric,
        sort: this.sortSlimSelectOrder.bind(this),
        setup: this.setupSlimSelectOrder.bind(this),
      },
    }

    const extension = extensions[this.extensionValue]
    if (extension) {
      extension.setup()
      Tablesort.extend(extensionFunctionName, extension.pattern, extension.sort)
      this.tablesort = new Tablesort(this.element)
    }
  }

  isNumeric(item) {
    return !isNaN(item)
  }

  sortSlimSelectOrder(a, b) {
    const slimValues = this.parentForm.querySelectorAll('.ss-value')
    const ids = Array.from(slimValues)
      .map(node => node.getAttribute('data-id'))
      .flatMap(dataID => Array.from(this.parentForm.querySelectorAll(`[id="${dataID}"]`)))
      .map(option => parseInt(option.getAttribute('value'), 10))

    return ids.indexOf(parseInt(a, 10)) - ids.indexOf(parseInt(b, 10))
  }

  setupSlimSelectOrder() {
    this.parentForm = this.element.closest('form')
  }
}
