import {BaseUrlAction} from "./base_url_action_controller";

export default class extends BaseUrlAction {
  static targets = ['item']
  static classes = ['selected']

  urlAction(target) {
    this.selectImpl(target)
  }

  select(event) {
    this.selectImpl(event.target)
  }

  selectImpl(target) {
    $(this.itemTargets).removeClass(this.selectedClass)
    $(target).closest('tr').addClass(this.selectedClass)
    let radioInput = $(target).closest('tr').find('input[type=radio]')[0]
    if (radioInput) {
      radioInput.checked = true
    }
  }
}
