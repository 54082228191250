import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['panel', 'list']
  static values = {
    hiddenClass: { type: String, default: 'hidden' },
    listHiddenClasses: { type: Array, default: ['hidden', 'sm:block'] }
  }

  showPanel() {
    this.panelTarget.classList.remove(this.hiddenClassValue)
    this.listTarget.classList.add(...this.listHiddenClassesValue)
  }

  hidePanel() {
    this.panelTarget.classList.add(this.hiddenClassValue)
    this.listTarget.classList.remove(...this.listHiddenClassesValue)
  }
}
