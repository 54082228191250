import { Controller } from "stimulus";

const safeMargin = 4
const bigSafeMargin = 40

export default class extends Controller {
  static targets = ["scrollable", "left", "right"];

  static values = {
    columns: { type: Number, default: 6 },
  }

  connect() {
    this.calculateSnappingPoints()
    this.position = 0

    this.tables = Array.from(this.scrollableTarget.getElementsByTagName('table'))

    this.resize()
    window.addEventListener('resize', this.resize.bind(this))
  }

  calculateSnappingPoints() {
    const snappable = Array.from(this.scrollableTarget.getElementsByClassName('snapping'))
    const snappableLeft = snappable.map(el => el.getBoundingClientRect().left)
    const uniqueSnappableLeft = [...new Set(snappableLeft)]
    uniqueSnappableLeft.sort((a, b) => a - b)

    this.snappingPoints = uniqueSnappableLeft.map(el => el - uniqueSnappableLeft[0])

    const snappableRight = snappable.map(el => el.getBoundingClientRect().right)
    this.endSnappingPoint = Math.max(...snappableRight) - uniqueSnappableLeft[0] // right position of last column

    this.maxWidth = this.endSnappingPoint + safeMargin
    if(uniqueSnappableLeft.length > this.columnsValue)
      this.maxWidth = uniqueSnappableLeft[this.columnsValue] - uniqueSnappableLeft[0] - safeMargin

    this.element.style.setProperty('--max-width', `${this.maxWidth}px`)
  }
  scrollLeft() {
    this.updatePosition(-1)
    this.updateLeft()
  }

  scrollRight() {
    this.updatePosition(1)
    this.updateLeft()
  }

  hideLeftButton() {
    this.leftTarget.classList.add('hidden');
  }

  showLeftButton() {
    this.leftTarget.classList.remove('hidden');
  }

  hideRightButton() {
    this.rightTarget.classList.add('hidden');
  }

  showRightButton() {
    this.rightTarget.classList.remove('hidden');
  }

  resize() {
    this.updatePosition(0)
    this.updateLeft()
  }

  updatePosition(offset) {
    this.position += offset

    let lastIndex = this.snappingPoints.length - 1
    for(let i = 0; i <= lastIndex; i++) {
      if(this.endSnappingPoint - this.snappingPoints[i] <= this.scrollableTarget.clientWidth) {
        lastIndex = i
        break
      }
    }

    this.position = Math.max(0, this.position)
    this.position = Math.min(lastIndex, this.position)

    if(this.position === 0)
      this.hideLeftButton()
    else
      this.showLeftButton()
    if(this.position === lastIndex)
      this.hideRightButton()
    else
      this.showRightButton()
  }

  updateLeft() {
    const value = this.snappingPoints[this.position]
    const x = Math.min(this.endSnappingPoint - this.scrollableTarget.clientWidth + bigSafeMargin, value)
    this.setLeft(Math.min(-x, 0))
  }

  setLeft(x) {
    this.tables.forEach(table => {
      table.style.left = `${x}px`
    })
  }
}
