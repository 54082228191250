global.updateFixedHeaderTable = function(){
  var interv = setInterval(function(){
    $(window).scroll();
  }, 1);
  setTimeout(function(){
    clearInterval(interv);
  }, 1000);
}

global.hide_button = function(button, opp_document_id) {
  var elem = $('#' + button.id);
  elem.remove();
  var badge = $('#badge-' + opp_document_id);
  var value = badge.attr('data-badge-caption');
  if (value === '1 new comment') {
    badge.remove();
  } else {
    if (parseInt(value) - 1 > 1) {
      badge.attr('data-badge-caption', parseInt(value) - 1 + ' new comments');
    } else {
      badge.attr('data-badge-caption', parseInt(value) - 1 + ' new comment');
    }
  }
}

global.select_rate_band_old = function(obj, comp_id, plan_id, employee, employee_spouse, employee_children, family){
  inputs = $('.' + 'premium-' + comp_id + '-' + plan_id);
  inputs[0].value = employee;
  inputs[1].value = employee_spouse;
  inputs[2].value = employee_children;
  inputs[3].value = family;
  inputs.toArray().forEach((input) => {
    input.dispatchEvent(new Event('input', { bubbles: true }))
  });
}

global.update_current_plan_select = function(obj, comp_id){
  $('#current_plan_div_' + comp_id).load('/peos/' + obj.options[obj.selectedIndex].value + '/get_plans_select/' + comp_id);
}

global.update_empty_renewals = function(input_class, selected){
  $.each($('input.' + input_class), function(i, v){
    if(v.value == ''){
      v.value = selected.value;
    }
  });
}

global.select_single_band = function(plan_id, plan_type, rate_band_id){
  if (rate_band_id !== undefined) {
    $('#report_plan_details_rate_band_'+ plan_type + '_' +plan_id)[0].value = rate_band_id;
    inputs = $('.premium-' + plan_type + '-' + plan_id);
    var data = $('[data-band-plan-id=' + plan_id + '][data-band-id=' + rate_band_id + ']').data();
    inputs[0].value = data['bandEmployee'];
    inputs[1].value = data['bandEmployeeSpouse'];
    inputs[2].value = data['bandEmployeeChildren'];
    inputs[3].value = data['bandFamily'];
  }
}

global.select_tier = function(tier_number){
  $.each($('input.rate-band.tier-'+ tier_number +':radio'), function(i, obj){
    $(obj).prop("checked", true);
    var plan_id = $(obj).attr('plan_id');
    var plan_type = $(obj).attr('plan_type');
    var rate_band_id = $(obj).attr('rate_band_id');
    select_single_band(plan_id, plan_type, rate_band_id);
  });
}

global.fill = function(field, selector) {
  if (selector == 'out') {
    if (field.value == 'n/a') {
      $.each($('.' + selector + ':visible'), function (i, obj) {
        if (obj.value == '') {
          obj.value = 'n/a';
        }
      })
    }
  }

  var fields = ['primary_care_copay_out', 'specialist_copay_out', 'hospital_inpatient_out', 'hospital_outpatient_out', 'diagnostic_test_out', 'imaging_out', 'laboratory_services_out',
    'primary_care_copay_in', 'specialist_copay_in', 'hospital_inpatient_in', 'hospital_outpatient_in', 'diagnostic_test_in', 'imaging_in', 'laboratory_services_in'];

  if (fields.indexOf($('#' + field.id).attr('data-field')) > -1) {
    if (field.value == 'Ded. & Co.') {
      $.each($('.' + selector +':visible'), function(i, obj) {
        var elem = $('#' + obj.id).attr('data-field');
        if (fields.indexOf(elem) > -1) {
          if(obj.value == ''){
            obj.value = 'Ded. & Co.';
          }
        }
      });
    }
  }
}

global.form_fill = function(field, selector) {
  if (selector == 'out') {
    if (field.value == 'n/a') {
      $.each($('.' + selector), function (i, obj) {
        var elem = $('#' + obj.id);
        if (obj.value == '') {
          elem.focus();
          obj.value = 'n/a';
        }
      })
      field.focus();
    }
  }

  var fields = ['medical_plan_primary_care_copay_out', 'medical_plan_specialist_copay_out', 'medical_plan_hospital_inpatient_out', 'medical_plan_hospital_outpatient_out',
    'medical_plan_diagnostic_test_out', 'medical_plan_imaging_out', 'medical_plan_primary_care_copay_in', 'medical_plan_specialist_copay_in',
    'medical_plan_hospital_inpatient_in', 'medical_plan_hospital_outpatient_in', 'medical_plan_diagnostic_test_in', 'medical_plan_imaging_in', 'medical_plan_diagnostic_test_in',
    'medical_plan_laboratory_services_in', 'medical_plan_laboratory_services_out'
  ];

  if (fields.indexOf(field.id) > -1) {
    if (field.value == 'Ded. & Co.') {
      $.each($('.' + selector), function (i, obj) {
        var elem = $('#' + obj.id);
        if (fields.indexOf(obj.id) > -1) {
          if (obj.value == '') {
            elem.focus();
            obj.value = 'Ded. & Co.';
          }
        }
      });
      field.focus();
    }
  }
}

global.isEmptyInput = function( el ){
  return !$.trim(el.value)
}

global.isAnyRequiredFieldEmpty = function(){
  var result = false;
  $('[required=required]').each(function(){
    if(isEmptyInput(this)){
      $(this).css('border', '2px solid red');
      result = true;
    }
  });
  return result;
}

global.set_rate = function(state_id, entity_id) {
  $.ajax({
    url: '/states/' + state_id + '/rate',
    method: 'GET',
    success: function (response, status, xhr) {
      $('.current_rate_' + state_id + '_' + entity_id).val(response.rate);
    }
  });
}

global.set_mod = function(field, selector) {
  $.each($('.'+selector), function(i, obj) {
    var elem = $('#' +obj.id);
    if (obj.value == '') {
      obj.value = field.value
    }
  })
}

global.update_status_and_file_list = function(dropzone_form, prospect_id, opportunity_id){
  var op_document_id = $(dropzone_form.element).data('opportunityDocumentId');
  $('#status-indicator-' + op_document_id).html('<span class="pending">Pending Review<i class="material-icons">rate_review</i></span>');
  $.ajax({
    url: '/prospects/' + prospect_id + '/opportunities/' + opportunity_id + '/opportunity_documents/' + op_document_id + '/uploaded_files',
    method: 'GET',
    success: function (data) {
      $('#uploaded-files-' + op_document_id ).html(data);
    }
  });
}
