import {Controller} from 'stimulus'
import _forEach from 'lodash/forEach'

export default class extends Controller {
  connect () {
    this.scroll()

    const controller = this
    this.observer = new MutationObserver((mutationList) => {
      _forEach(mutationList, (mutation) => {
        _forEach(mutation.addedNodes, (node) => {
          if (node.dataset && node.dataset.justCreated === 'true') {
            controller.scroll()
            return false
          }
        })
      })
    })
    this.observer.observe(this.element, {subtree: true, childList: true})
  }

  scroll () {
    this.element.scrollTop = this.element.scrollHeight
  }

  disconnect () {
    this.observer.disconnect()
  }
}
