import {Controller} from 'stimulus'
import Inputmask from "inputmask";

export default class extends Controller {
  static targets = ['input']
  static values = {
    digits: Number
  }

  connect() {
    const input = this.inputTarget
    const digits = this.digitsValue
    Inputmask(
      'currency',
      {
        prefix: '$',
        rightAlign: false,
        removeMaskOnSubmit: true,
        showMaskOnHover: false,
        digits: digits
      }
    ).mask(input)
  }
}
