import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['tab']

  initialize () {
    this.panels = []
  }

  connect () {
    // this timeout is need fot table-slider-controller:
    // updateHeights() and showSlidingTables() methods need to get elements widths. It's not possible
    // for display:none elements - Tabs plugin hides them and it's impossible get that value

    if (document.readyState === 'complete') {
      document.fonts.ready.then(this.setup.bind(this))
    } else {
      window.addEventListener('load', this.setup.bind(this))
    }
  }

  setup() {
    setTimeout(() => {
      this.tabTargets.forEach((tab, index) => {
        this.panels.push(tab.getAttribute('href'))

        if (index === 0) {
          tab.classList.add('active')
          $(tab.getAttribute('href')).show()
        } else {
          tab.classList.remove('active')
          $(tab.getAttribute('href')).hide()
        }
      })
    }, 1)
  }
  open (event) {
    event.preventDefault()

    this.tabTargets.forEach((tab, index) => {
      tab.classList.remove('active')
    })
    event.currentTarget.classList.add('active')

    this.panels.forEach((panelId, _index)=>{
      $(panelId).hide()
    })
    $(event.currentTarget.getAttribute('href')).show()
  }
};
