import Rails from "@rails/ujs";

require('slim-select')
import 'slim-select/styles'
import {Controller} from 'stimulus'
import SlimSelect from 'slim-select'

export default class extends Controller {
  static values = {
    placeholder: String,
    contextProfileId: Number,
    contextOpportunityId: Number,
  }

  connect() {
    let controller = this
    this.slimselect = new SlimSelect({
      select: this.element,
      settings: {
        closeOnSelect: true,
        showSearch: false,
        placeholder: this.hasPlaceholderValue ? this.placeholderValue : null
      },
      events: {
        afterChange: function (conversation) {
          let conversationId = conversation[0].value
          let params = new URLSearchParams({
            context_profile_id: controller.contextProfileIdValue,
            context_opportunity_id: controller.contextOpportunityIdValue
          })

          const frame = document.getElementById('side-modal')
          let showConversationUrl = new URL('/admin/conversations/' + conversationId, window.location)
          showConversationUrl.search = params
          frame.src = showConversationUrl.toString()
        }
      }
    })
    let slimSelectElement = this.element.nextElementSibling;
    slimSelectElement.style.borderWidth = '0'
  }

  disconnect() {
    this.slimselect.destroy()
  }
}
