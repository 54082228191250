export default class TurboFrameSearch {
  constructor(parameters, frame_callback, url) {
    this.parameters = parameters
    this.get_frame = frame_callback
    this.url = url
  }

  getParameter(key) {
    return encodeURIComponent(this.parameters[key]().value)
  }

  buildUrlArguments() {
    return '?' + Object.keys(this.parameters)
      .filter( key => this.getParameter(key) )
      .map( key => `${key}=${this.getParameter(key)}` )
      .join( '&' )
  }

  search() {
    // this updates URL without redirect
    const urlArguments = this.buildUrlArguments()
    const frame = this.get_frame()
    const url = this.url

    window.history.pushState("", "", urlArguments);

    frame.loaded.then(function (success) {
      // this update SRC in a TURBOFRAME when frame is loaded
      frame.src = url + urlArguments
    }, function (error) {});
  }
};
