import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ['status', 'fileField']

  connect() {
    this.reset()
  }

  selectFiles() {
    this.fileFieldTarget.click()
  }

  changedSelection() {
    let fileNames = Array.from(this.fileFieldTarget.files).map(file => file.name)
    this.statusTarget.textContent = fileNames.join('\n')
  }

  reset() {
    this.statusTarget.textContent = 'No file chosen...'
  }
}
