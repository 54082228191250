import { Controller } from 'stimulus';
import Rails from '@rails/ujs'

export default class extends Controller {
  static get targets () {
    return ["row"]
  }

  initialize () {
    this.reportId = parseInt(this.element.getAttribute('data-report-id'))
  }

  toggle (event) {
    let summaryFieldId = event.currentTarget.getAttribute('data-summary-field-id')
    let reportId = this.reportId
    let tr = $(event.currentTarget).closest('tr');

    Rails.ajax({
      url: '/summary_hidden_rows/toggle/' + reportId + '/' + summaryFieldId,
      type: 'get',
      success: (data, status, xhr) => {
        if (data.status === 'hidden') {
          tr.addClass('invisible-row')
        } else {
          tr.removeClass('invisible-row')
        }
      }
    })
  }
};
