import Rails from '@rails/ujs'

import _forEach from 'lodash/forEach'
import _mapKeys from 'lodash/mapKeys'
import _map from 'lodash/map'
import _groupBy from 'lodash/groupBy'
import _includes from 'lodash/includes'
import numbro from 'numbro/dist/numbro.min'
import _compact from "lodash/compact";

export const request = (url, method, data, controller) => {
  Rails.ajax({
    type: method,
    url: url,
    beforeSend: (xhr, options) => {
      xhr.setRequestHeader('Content-Type', 'application/json')
      options.data = data
      controller.startLoading()
      controller.disableButtons()
      return true
    },
    success: function (data) {
      controller.disableReadOnlyCells(data.readOnlyRows)
      controller.populateMetaData(data)
      controller.setLastDataLoaded()
      controller.loadErrors(data)
      controller.finishLoading()
      controller.afterSaveResponse()
    },
    complete: (xhr, status) => {
      controller.enableButtons()
    },
    error: (response, status, xhr) => {
      if(xhr.status === 401){
        window.location = window.location.href
      }
    }
  })
}

export const groupErrorsByMessage = (errors) => {
  let errorsByMessage = []
  _forEach(errors, (row) => {
    _mapKeys(row.errors, (messages, fieldName) => {
      _forEach(messages, (message) => {
        errorsByMessage.push({field: fieldName, message: message, id: row.id, row: row.index})
      })
    })
  })

  return _groupBy(errorsByMessage, 'message')
}

export const cellsForMerge = (data) => {
  let res = 0
  return _compact(_map(groupedFullData(data), (rows, position) => {
    let r = [res, rows.length]
    res = res + rows.length
    if (rows.length < 2) { return }
    return r
  }))
}

export const clearCurrencyCells = (changes) => {
  let currencyColumns = ['annual_wage', 'annual_commissions', 'annual_bonus']
  _forEach(changes, (row) => {
    if(_includes(currencyColumns, row[1]) && typeof(row[3]) === 'string'){
      row[3] = numbro.unformat(row[3])
    }
  })
}

export const preventCreatingRowsOnPaste = (source) => {
  return source !== 'CopyPaste.paste'
}

export const updatePositions = (fullData) => {
  _forEach(fullData, (row, i) => { if (row.id) { row.position = i } })
}

export const mapToAcceptedFormats = (changes) => {
  const mapping = {
    'gender': {
      'm': 'M', 'male': 'M',
      'f': 'F', 'female': 'F'
    },
    'health_coverage_level': {
      'ne': 'NE', 'not eligible': 'NE',
      'wc': 'WC', 'waive cov': 'WC',
      'e': 'E', 'ee': 'E', 'employee': 'E', 'employee only': 'E',
      'es': 'ES', 'spouse': 'ES', 'employee + spouse': 'ES',
      'ec': 'EC', 'children': 'EC', 'employee + children': 'EC',
      'f': 'F', 'ef': 'F', 'family': 'F', 'employee + family': 'F',
    },
    'work_status': {
      'f': 'F', 'ft': 'F', 'full-time': 'F', 'full time': 'F',
      'p': 'P', 'pt': 'P', 'part-time': 'P', 'part time': 'P',
      'k1': 'K1', 'k-1': 'K1'
    },
    'pay_type': {
      's': 'S', 'salaried': 'S', 'salary': 'S',
      'h': 'H', 'hourly': 'H',
      'k1': 'K1', 'k-1': 'K1'
    }
  }
  _forEach(changes, (row) => {
    const colMapping = mapping[row[1]]
    if (colMapping && row[3]) {
      const formattedGender = colMapping[row[3].toLowerCase()]
      if (formattedGender && row[3]) { row[3] = formattedGender }
    }
  })
}
