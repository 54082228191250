import {Controller} from 'stimulus'
import Rails from "@rails/ujs"
import serializedForms from '../admin/serialized_forms'

export default class extends Controller {
  static targets = [
    'form', 'counter'
  ]

  get changedElementClasses() {
    return ['!outline-1', '!outline', '!outline-red', '!outline-offset-0', 'save-bar-changed-field']
  }

  get changedElements() {
    return new Set(this.formTargets.flatMap(form => Array.from(this.getChangedChildren(form))))
  }

  get changedForms() {
    return new Set(this.formTargets.filter(form => this.getChangedChildren(form).length > 0))
  }

  formTargetConnected(form) {
    this.setDefaultValuesToCurrent(form)
    form.addEventListener('input', this.formInput.bind(this))
    this.updateCounter()
  }

  formTargetDisconnected(form) {
    form.reset()
    form.dispatchEvent(new InputEvent('input', {bubbles: true}))
    serializedForms.reset(form)
  }

  counterTargetConnected(counter) {
    this.updateCounter()
  }

  setDefaultValuesToCurrent(form) {
    form.querySelectorAll('select').forEach(select => select.defaultValue = select.value)
    form.querySelectorAll('input').forEach(input => {
      input.defaultValue = input.value

      if (input.type === 'number' && !input.defaultValue) {
        input.defaultValue = NaN
      }
    })
  }

  save() {
    this.changedForms.forEach(form => Rails.fire(form, 'submit'))
    this.updateCounter()
  }

  cancel() {
    const changedElementsBeforeReset = this.changedElements
    const changedFormsBeforeReset = this.changedForms
    changedFormsBeforeReset.forEach(form => form.reset())
    changedElementsBeforeReset.forEach(el => el.dispatchEvent(new InputEvent('input', {bubbles: true})))
    changedFormsBeforeReset.forEach(form => serializedForms.reset(form))
    this.updateCounter()
  }

  formInput(event) {
    const target = event.target
    const isTargetWithChangedElementClasses = this.isChanged(target)

    if (!isTargetWithChangedElementClasses) {
      this.addChangedElementClasses(target)
    } else if (target.defaultValue === target.value) {
      this.removeChangedElementClasses(target)
    }

    this.updateCounter()
  }

  updateCounter() {
    const changedElementsCount = this.changedElements.size
    this.counterTarget.innerText = changedElementsCount

    if (changedElementsCount === 0)
      this.element.classList.remove('save-bar-visible')
    else if (changedElementsCount > 0)
      this.element.classList.add('save-bar-visible')
  }

  getChangedChildren(element) {
    return element.querySelectorAll(
      'input.save-bar-changed-field, select.save-bar-changed-field, textarea.save-bar-changed-field'
    )
  }

  isChanged(element) {
    return element.classList.contains(...this.changedElementClasses)
  }

  addChangedElementClasses(element) {
    element.classList.add(...this.changedElementClasses)
  }

  removeChangedElementClasses(element) {
    element.classList.remove(...this.changedElementClasses)
  }
}
