import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["table1", "table2"];

  connect() {
    this.calculated = false
    document.fonts.ready.then(this.equalizeRowHeights.bind(this))
  }

  equalizeRowHeights() {
    if(this.calculated) return;
    this.calculated = true;

    const rows1 = this.table1Target.querySelectorAll('tr');
    const rows2 = this.table2Target.querySelectorAll('tr');

    if (rows1.length !== rows2.length) {
      console.error("Tables have different number of rows");
      return;
    }

    for (let i = 0; i < rows1.length; i++) {
      const row1Height = rows1[i].getBoundingClientRect().height;
      const row2Height = rows2[i].getBoundingClientRect().height;

      if (row1Height >= row2Height)
        rows2[i].style.height = `${row1Height}px`;
      else
        rows1[i].style.height = `${row2Height}px`;
    }
  }
}
