import {Controller} from 'stimulus'
import {debounce} from "lodash";

export default class extends Controller {

  static targets = ['search', 'body', 'checkbox']

  searchTargetConnected(element) {
    element.placeholder = 'Search...'
    element.addEventListener('input', this.filter)
    element.addEventListener('click', ev => ev.stopPropagation())
  }

  checkboxTargetConnected(element) {
    element.addEventListener('click', ev => {
      ev.stopPropagation()
      this.filter()
    })
  }

  filter = debounce(this._filter.bind(this), 200)

  _filter() {
    this.bodyTarget.querySelectorAll('tr').forEach(row => {
      row.classList.remove('hidden')

      this.searchTargets.forEach(searchTarget => {
        const column = this._getColumnIndex(searchTarget)

        if (!this._includesCaseInsensitive(this._getNthChild(row, column)?.textContent || '', searchTarget.value))
          row.classList.add('hidden')
      })

      this.checkboxTargets.forEach(checkboxTarget => {
        const column = this._getColumnIndex(checkboxTarget)

        if (checkboxTarget.checked && this._isBlankOrZero(this._getNthChild(row, column)?.textContent || ''))
          row.classList.add('hidden')
      })
    })
  }

  _getColumnIndex(element) {
    return Array.from(element.closest('tr').children).indexOf(element.closest('th, td'))
  }

  _getNthChild(element, n) {
    return Array.from(element.children)[n]
  }

  _isBlankOrZero(string) {
    return string <= 0
  }

  _includesCaseInsensitive(string, substring) {
    return string.toLowerCase().includes(substring.toLowerCase())
  }
}
