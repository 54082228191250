import {Controller} from 'stimulus'

export default class extends Controller {
  static get targets () {
    return ['renewalDateInput', 'selectRatesModal']
  }
  static values = {groupUrl: String}

  resetRenewalDates(event){
    $(this.renewalDateInputTargets).each(function(i, el){
      if(el.value !== '') {
        el.value = ''
        el.dispatchEvent(new Event('input', { bubbles: true }))
      }
    })
  }

  updateAllDates(event){
    $(this.renewalDateInputTargets).each(function(i, el){
      if(el.value === '' && event.target.value !== el.value){
        el.value = event.target.value
        el.dispatchEvent(new Event('input', { bubbles: true }))
      }
    })
  }

  loadRateBands(event){
    const frame = document.getElementById('rates-form-modal')
    frame.innerHTML = ''
    const _this = this
    frame.loaded.then(function (success) {
      frame.src = _this.groupUrlValue + "?refresh=" + Date.now()
    }, function (error) {});
 }
}

