require('slim-select')
import 'slim-select/styles'
import { Controller } from 'stimulus'
import SlimSelect from 'slim-select'

export default class extends Controller {
  static values = {
    placeholder: String
  }

  connect() {
    this.element.classList.add('slimselect-custom-display')
    this.isLastChangeTriggeredByFormReset = false
    this.slimselect = new SlimSelect({
      select: this.element,
      settings: {
        showSearch: false,
        closeOnSelect: true,
      },
      events: {
        afterClose: () => {
          this.setSelectedOptionOnOriginalSelect();
        },
        afterChange: () => {
          if (this.isLastChangeTriggeredByFormReset === true) {
            this.isLastChangeTriggeredByFormReset = false
          }
          else {
            this.element.dispatchEvent(new Event('input', {bubbles: true}));//needed to trigger the save bar
          }
        }
      }
    })
    this.initialSelected = this.slimselect.getSelected()

    this.addCustomDisplayToOptions();
  }

  addCustomDisplayToOptions() {
    const {slimselect} = this;

    const dataWithCustomDisplayOptions = slimselect.getData().map((optionsOrOptionsGroup) => {
      if (!optionsOrOptionsGroup.options) {
        return this.addCustomDisplayToOption(optionsOrOptionsGroup);
      } else {
        return {
          ...optionsOrOptionsGroup,
          options: optionsOrOptionsGroup.options.map(this.addCustomDisplayToOption)
        };
      }
    });
    const formElement = this.element.closest('form')
    if (formElement) {
      formElement.addEventListener('reset', this.reset.bind(this))
    }


    slimselect.setData(dataWithCustomDisplayOptions);
  }

  reset(event) {
    this.isLastChangeTriggeredByFormReset = true
    this.slimselect.setSelected(this.initialSelected)
    this.setSelectedOptionOnOriginalSelect();
  }

  addCustomDisplayToOption = (dataOption) => ({
    ...dataOption,
    html: dataOption.data.display ? `${dataOption.text} <span class="custom-display"> - ${dataOption.data.display}</span>` : dataOption.text,
  });

  setSelectedOptionOnOriginalSelect() {
    // SlimSelect's setData re-renders the select element, but doesn't set the selected attribute on the option element of the original select.
    // This attribute is needed for the form to be reset correctly. Without it, the form will be reset to the first option instead of the previously set option.
    const selectedOptionIndex = this.element.selectedIndex;
    this.element.options[selectedOptionIndex].setAttribute('selected', 'selected');
    this.element.options[0].setAttribute('value', '');
  }

  disconnect() {
    this.slimselect.destroy()
  }
}
