import {Controller} from 'stimulus'

export default class extends Controller {
  static outlets = ['modal', 'side-modal']

  connect() {
    this.clickHandler = this.closeTopModalOnOutsideClick.bind(this)
    this.escHandler = this.closeTopModalOnEsc.bind(this)
    addEventListener('click', this.clickHandler);
    addEventListener('keydown', this.escHandler);
  }

  closeTopModalOnOutsideClick(event) {
    if (event.target.classList.contains('js-modal-bg')) {
      this.closeTopModal(event);
    }
  }

  closeTopModalOnEsc(event) {
    if (event.which === 27) {
      this.closeTopModal(event);
    }
  }

  closeTopModal(event) {
    if (this.hasModalOutlet || this.hasSideModalOutlet) {
      let topModal = this.modalOutlets.concat(this.sideModalOutlets).reduce((prev, curr) => prev.modalStackPositionValue > curr.modalStackPositionValue ? prev : curr)
      topModal.close(event)
    }
  }

  disconnect() {
    removeEventListener('click', this.clickHandler);
    removeEventListener('keydown', this.escHandler);
  }
}
