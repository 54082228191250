import _includes from 'lodash/includes'
import _compact from 'lodash/compact'
import * as Utils from './utils'

const getFixedColumnsForScreenSize = (fixedColumns) => {
  if (window.innerWidth >= 768) {
    return fixedColumns
  }
  return 0
}

const commonSettings = (controller) => {
  return {
    licenseKey: 'e75ea-a440e-28e75-34d22-b0625',
    persistentState: true,
    rowHeaders: function(index) {
      if(controller.ht?.getSourceDataAtCell(index, 'id'))
        return `<div class="row-with-data">${index + 1}</div>`
      return `<div class="row-without-data">${index + 1}</div>`
    },
    rowHeights: 30,
    // height: 300,
    className: 'htMiddle',
    fillHandle: true,
    manualColumnResize: true,
    viewportRowRenderingOffset: 'auto',
    stretchH: "all",
    undo: false, // not supported for now
    readOnly: isReadOnly(controller),
    afterChange: (change, source) => {
      if (_includes(controller.afterChangeAllowedSources, source)) {
        controller.save()
      }
    },
    afterCreateRow: (index, amount, source) => {
      if (_includes(controller.afterCreateRowAllowedSources, source)) {
        // don't add rows if they are added below the last row
        if(index < _compact(controller.ht.getDataAtProp('id')).length){
          controller.ht.setSourceDataAtCell(index, 'id', `new_row ${index}`)
          controller.save()
        }
      }
    },
    beforeRemoveRow: (index, amount) => {
      controller.deleteRows(index, amount)
    },
    afterViewRender: (_isForced)=>{
      controller.showErrors()
    },
    afterGetRowHeader: function(col, TH) {
      TH.className = 'align-middle'
    }
  }
}

const dateField = (name) => {
  return {
    data: name,
    type: 'date',
    dateFormat: 'MM/DD/YYYY',
    correctFormat: true,
    defaultDate: '01/01/2020',
    datePickerConfig: {
      firstDay: 0,
      showWeekNumber: true,
      numberOfMonths: 3
    }
  }
}

const workStatuses = ['F', 'P', 'K1']
const stateCodes = [
  'DC', 'CT', 'NJ', 'NY', 'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA',
  'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NM', 'NC', 'ND', 'OH',
  'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'VI', 'WA', 'WV', 'WI', 'WY'
].sort()

const contextMenu = (includeRowManipulation = true) => {
  let rowsManipulation = null
  if (includeRowManipulation) {
    rowsManipulation = {
      'row_above': {},
      'row_below': {},
      'remove_row': {},
      '---------': {}
    }
  }
  return {
    items: {
      ...rowsManipulation,
      'copy': {},
      'cut': {},
      'paste': {
        name: 'Paste',
        // this doesn't work, disabled can't take async function
        disabled: async function () {
          return await navigator.clipboard.readText().then(
            text => text.length === 0
          )
        },
        callback: function () {
          var plugin = this.getPlugin('copyPaste')

          this.listen()
          navigator.clipboard.readText().then(
            text => {plugin.paste(text)},
            reason => {console.log('Paste issue')}
          )
        }
      }
    }
  }
}

const tooltipped = (id, name, controller) => name + ' ' + controller.tooltip(id)

export const regularEmployee = (controller, customConfig) => {
  const fixedColumns = 4
  return {
    ...customConfig,
    ...commonSettings(controller),
    beforeChange: (changes, _source) => {
      Utils.mapToAcceptedFormats(changes)
    },
    contextMenu: contextMenu(),
    startRows: 30,
    minSpareRows: 40,
    colHeaders: ['ID', 'position', 'First Name', 'Last Name',
      tooltipped('gender', 'Gender', controller),
      'Birth Date',
      tooltipped('zipcode', 'Home Zip Code', controller),
      tooltipped('health_coverage_level', 'Health Coverage Level', controller),
      tooltipped('medical_plan_name', 'Medical Plan Name', controller)],
    hiddenColumns: {
      columns: [0, 1],
      copyPasteEnabled: false,
      indicators: false
    },
    fixedColumnsStart: getFixedColumnsForScreenSize(fixedColumns),
    columns: [
      {data: 'id'},
      {data: 'position'},
      {data: 'first_name', width: 200},
      {data: 'last_name', width: 200},
      {
        data: 'gender',
        type: 'dropdown',
        source: ['F', 'M']
      },
      dateField('birth_date'),
      {
        data: 'home_zip_code',
        validator: 'oa.validator.zip-code'
      },
      {
        data: 'health_coverage_level',
        type: 'dropdown',
        source: ['NE', 'WC', 'E', 'ES', 'EC', 'F']
      },
      {data: 'plan_name', width: 150}
    ]
  }
}

export const workEmployee = (controller, customConfig, questionnaire_entities) => {
  const fixedColumns = 5
  let entity_field = {
    data: 'entity_name',
    width: 150
  }
  if(questionnaire_entities && questionnaire_entities.length > 0)
    entity_field = {
      ...entity_field,
      type: 'dropdown',
      source: questionnaire_entities
    }
  return {
    ...customConfig,
    ...commonSettings(controller),
    beforeCreateRow: (_index, _amount, source) => {
      return Utils.preventCreatingRowsOnPaste(source)
    },
    beforeChange: (changes, _source) => {
      Utils.clearCurrencyCells(changes)
      Utils.mapToAcceptedFormats(changes)
    },
    contextMenu: contextMenu(false),
    minSpareRows: 0,
    colHeaders: ['ID', 'position', 'opportunity_id', 'prospect_id' ,'Full Name',
      'Work Address', 'Work City',
      tooltipped('state', 'State', controller),
      tooltipped('zipcode', 'Zip Code', controller),
      'Job Title',
      'Hire Date',
      tooltipped('work_status', 'Work Status', controller),
      'Annual Base Wages', 'Annual Commission', 'Annual Bonus',
      tooltipped('pay_type', 'Pay Type', controller),
      tooltipped('weekly_work_hours', 'Weekly Work Hours', controller),
      tooltipped('wc_code', 'WC Code', controller),
      tooltipped('entity_name', 'Entity Name', controller)],
    hiddenColumns: {
      columns: [0, 1, 2, 3],
      indicators: false
    },
    fixedColumnsStart: getFixedColumnsForScreenSize(fixedColumns),
    fillHandle: {
      autoInsertRow: false
    },
    columns: [
      {data: 'id', readOnly: true, editor: false},
      {data: 'position', readOnly: true, editor: false},
      {data: 'opportunity_id', readOnly: true, editor: false},
      {data: 'prospect_id', readOnly: true, editor: false},
      {data: 'full_name', readOnly: true, editor: false, renderer: 'oa.renderer.work-employee-full-name'},
      {data: 'work_address', width: 200},
      {data: 'work_city', width: 150},
      {
        data: 'state',
        type: 'dropdown',
        source: stateCodes
      },
      {
        data: 'work_zip_code',
        validator: 'oa.validator.zip-code'
      },
      {data: 'job_title'},
      dateField('hire_date'),
      {
        data: 'work_status',
        type: 'dropdown',
        source: workStatuses
      },
      {
        data: 'annual_wage',
        type: 'numeric',
        numericFormat: {
          pattern: '$0,0.00',
          culture: 'en-US'
        }
      },
      {
        data: 'annual_commissions',
        type: 'numeric',
        numericFormat: {
          pattern: '$0,0.00',
          culture: 'en-US'
        }
      },
      {
        data: 'annual_bonus',
        type: 'numeric',
        numericFormat: {
          pattern: '$0,0.00',
          culture: 'en-US'
        }
      },
      {
        data: 'pay_type',
        type: 'dropdown',
        source: ['S', 'H', 'K1']
      },
      {
        data: 'weekly_work_hours',
        type: 'numeric'
      },
      {
        data: 'wc_code',
      },
      entity_field
    ]
  }
}

export const dependentEmployee = (controller, customConfig) => {
  const fixedColumns = 6
  return {
    ...customConfig,
    ...commonSettings(controller),
    beforeCreateRow: (_index, _amount, source) => {
      return Utils.preventCreatingRowsOnPaste(source)
    },
    beforeChange: (changes, _source) => {
      Utils.mapToAcceptedFormats(changes)
    },
    contextMenu: contextMenu(false),
    fillHandle: {
      // enable plugin in both directions and with autoInsertRow as false
      autoInsertRow: false
    },
    minSpareRows: 0,
    rowHeaders: function(index) {
      const parent_position = controller.ht?.getSourceDataAtCell(index, 'parent_position')
      if(parent_position === null || parent_position === undefined) return ''
      if(controller.ht?.getSourceDataAtCell(index, 'id'))
        return `<div class="row-with-data">${index - parent_position}</div>`
      return `<div class="row-without-data">${index - parent_position}</div>`
    },
    colHeaders: ['ID', 'Parent Position', 'Position', 'Parent ID', 'Can Destroy Via API',
      'First Name', 'Last Name',
      'Dependent Status',
      tooltipped('gender', 'Gender', controller),
      'Date of Birth',
      tooltipped('zipcode', 'Home Zip Code', controller),
      'Action'],
    hiddenColumns: {
      columns: [0, 1, 2, 3, 4],
      copyPasteEnabled: false,
      indicators: false
    },
    fixedColumnsStart: getFixedColumnsForScreenSize(fixedColumns),
    columns: [
      {data: 'id'},
      {data: 'parent_position',},
      {data: 'position'},
      {data: 'employee_id'},
      {data: 'can_destroy_via_api'},
      {data: 'first_name', width: 200},
      {data: 'last_name', width: 200},
      {
        data: 'status',
        type: 'dropdown',
        source: ['CH', 'SP']
      },
      {
        data: 'gender',
        type: 'dropdown',
        source: ['F', 'M']
      },
      dateField('birth_date'),
      {
        data: 'home_zip_code',
        validator: 'oa.validator.zip-code'
      },
      {
        renderer: 'html',
        data: 'action',
        readOnly: false,
        editor: false,
        disableVisualSelection: true,
      },
    ]
  }
}

export const cobraEmployee = (controller, customConfig) => {
  const fixedColumns = 4
  return {
    ...customConfig,
    ...commonSettings(controller),
    beforeChange: (changes, _source) => {
      Utils.mapToAcceptedFormats(changes)
    },
    contextMenu: contextMenu(),
    startRows: 10,
    minSpareRows: 10,
    colHeaders: ['ID', 'position', 'First Name', 'Last Name',
      tooltipped('gender', 'Gender', controller),
      'Birth Date',
      tooltipped('zipcode', 'Home Zip Code', controller),
      tooltipped('health_coverage_level', 'Health Coverage Level', controller),
      tooltipped('medical_plan_name', 'Medical Plan Name', controller),
      tooltipped('cobra_status', 'Cobra Status', controller),
      'Cobra Start Date', 'Cobra End Date'],
    hiddenColumns: {
      columns: [0, 1],
      copyPasteEnabled: false,
      indicators: false
    },
    fixedColumnsStart: getFixedColumnsForScreenSize(fixedColumns),
    columns: [
      {data: 'id'},
      {data: 'position'},
      {data: 'first_name'},
      {data: 'last_name'},
      {
        data: 'gender',
        type: 'dropdown',
        source: ['F', 'M']
      },
      dateField('birth_date'),
      {
        data: 'home_zip_code',
        validator: 'oa.validator.zip-code'
      },
      {
        data: 'health_coverage_level',
        type: 'dropdown',
        source: ['NE', 'WC', 'E', 'ES', 'EC', 'F']
      },
      {data: 'plan_name'},
      {
        data: 'cobra_status',
        type: 'dropdown',
        source: ['Active', 'Eligible']
      },
      dateField('cobra_start_date'),
      dateField('cobra_end_date')
    ]
  }
}

export const cobraDependentEmployee = (controller, customConfig) => {
  return dependentEmployee(controller, customConfig)
}

const isReadOnly = (controller) => {
 return _includes(['accepted', 'pending'], controller.censusStatusValue)
}
