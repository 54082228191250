export class TooltipCalculations {
  constructor(pop, header) {
    this.distance = 10;
    this.popWidth = pop.offsetWidth;
    this.popHeight = pop.offsetHeight;
    this.headerWidth = header.offsetWidth;
    this.headerHeight = header.offsetHeight;
    const headerRect = header.getBoundingClientRect();
    this.headerLeft = headerRect.left;
    this.headerTop = headerRect.top;

    this.windowWidth = document.documentElement.offsetWidth;
    this.windowHeight = document.documentElement.offsetHeight;

    this.setOffset()
    this.setIsBelow()
  }

  setIsBelow() {
    this.below = (this.headerTop - this.popHeight - this.distance) <= 0;
  }

  isBelow() {
    return this.below;
  }

  getLeft() {
    return this.getOffset() + this.headerLeft + this.headerWidth / 2 - this.popWidth / 2
  }

  getTop() {
    if (this.isBelow())
      return this.headerTop + this.headerHeight + this.distance;
    else
      return this.headerTop - this.popHeight - this.distance;
  }

  setOffset() {
    this.offset = 0;
    const left = this.getLeft();
    if (left < /*0*/64) // ugly fix for user-portal side menu. There is problem with z-index.
      this.offset = /*0*/64 - left;
    else if (left + this.popWidth > this.windowWidth - 64) // symmetry
      this.offset = this.windowWidth - 64 - left - this.popWidth;

    const range = this.popWidth / 2 - 10;
    this.offset = Math.max(-range, Math.min(this.offset, range));
  }

  getOffset() {
    return this.offset;
  }
}
