import {Controller} from 'stimulus'

export default class extends Controller {
  initialize () {
    let element = this.element
    // require([ "jquery", "jquery-ui/ui/widgets/sortable" ], function( $ ) {
    $(element).sortable({
      handle: '.secondary-content',
      stop: function (event, ui) {
        $('ul.collection li').each(function (idx, el) {
          $(el).find('.position').val(idx)
        })
      }
    })
    // })
  }
};
