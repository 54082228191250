
import * as Survey from 'survey-jquery/survey.jquery.min'
import * as SurveyPDF from 'survey-pdf/survey.pdf.min'
import {forEach} from 'lodash'

function setDateInUsFormat(question) {
  if (question.getType() === 'text' && question.inputType === 'date') {
    if(!question.isEmpty()) {
      const d = new Date(question.value);
      question.value = d.toLocaleDateString('en-US', {month:'2-digit', day:'2-digit', year:'numeric'})
    }
  }
}

function twoZeroFormat(value) {
  return value < 10 ? `0${value}` : `${value}`
}

export default {
  disableDescription: (question) => {
    question.description = ''
  },
  disableSelectAll: (question) => {
    question.hasSelectAll = false
  },
  disableOther: (question) => {
    if(!question.isOtherSelected)
      question.hasOther = false
  },
  disableNone: (question) => {
    if(question.hasNone) {
      if(question.isItemSelected(question.noneItem))
        question.hasNone = false
    }
  },
  getSelectedChoices: (question, choices) => {
    let selected_choices = []
    choices.forEach((choice) => {
      if(question.isItemSelected(choice))
        selected_choices.push(choice)
    })
    return selected_choices
  },
  useUsDateFormat: (surveyPDF) => {
    surveyPDF.getAllQuestions().forEach(q => {
      setDateInUsFormat(q)

      if (q.getType() === 'paneldynamic') {
        forEach(q.panels, (panel) => {
          forEach(panel.questions, (question) => {
            setDateInUsFormat(question)
          })
        })
      }
    })
  },
  getDateAsUTC: (date) => {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(),
      date.getHours(), date.getMinutes(), date.getSeconds()))
  },
  getTimezoneNeutralDate: (date) => {
    return `${date.getFullYear()}-${twoZeroFormat(date.getMonth()+1)}-${twoZeroFormat(date.getDate())}T00:00:00`
  }
}
