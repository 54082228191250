import {Controller} from 'stimulus'
import {forEach, debounce} from 'lodash'

export default class extends Controller {
  static values = {
    keys: Array
  }

  fillBelow = debounce((e) => this._fill(e, true), 200)

  fill = debounce(this._fill.bind(this), 200)

  fillNA = debounce(this._fillNA.bind(this), 200)

  _fill(e, onlyBelow = false) {
    const form = e.target.closest('form')
    const value = e.target.value
    if (!form || !this.keysValue.includes(value)) return

    const plan = e.params.plan
    let isBelow = false

    forEach(form.querySelectorAll(`[data-fields-filler-plan-param="${plan}"]`), element => {
      if (element === e.target) isBelow = true
      if ((!onlyBelow || isBelow) && element.value === '') this._setValueAndDispatchEvent(element, value)
    })
  }

  _fillNA(e) {
    const form = e.target.closest('form')
    const value = e.target.value
    if (!form || value !== 'n/a') return

    const plan = e.params.plan
    forEach(form.querySelectorAll(`[data-fields-filler-plan-param="${plan}"]`), element => {
      if (element.value === '') this._setValueAndDispatchEvent(element, 'n/a')
    })
  }

  _setValueAndDispatchEvent(element, value) {
    element.value = value
    element.dispatchEvent(new InputEvent('input', {bubbles: true}))
  }
}
