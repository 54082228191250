import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['menu', 'toggleButton', 'currentPageLabel', 'checkIcon']

  connect() {
    document.addEventListener('click', this.closeMenu.bind(this))
    this.setInitialLabel()
  }

  disconnect() {
    document.removeEventListener('click', this.closeMenu.bind(this))
  }

  toggle() {
    this.menuTarget.classList.toggle('hidden')
  }

  closeMenu(event) {
    if (!this.element.contains(event.target)) {
      this.menuTarget.classList.add('hidden')
    }
  }

  selectItem(event) {
    this.currentPageLabelTarget.textContent = event.currentTarget.dataset.label
    this.menuTarget.classList.add('hidden')
  }

  setInitialLabel() {
    const activeItem = this.menuTarget.querySelector('.is-active')
    if (activeItem) {
      this.currentPageLabelTarget.textContent = activeItem.dataset.label
    }
  }
}
