$(window).bind('turbo:load', function() {
  const root = document.documentElement;

  // detect Touch
  if ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
    root.classList.add('touch');
    window.touch = true;
  } else {
    root.classList.add('no-touch');
  }
})


