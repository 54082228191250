import {Controller} from 'stimulus'
import {TooltipCalculations} from "../src/tooltip_calculations";

export default class extends Controller {
  static targets = ['pop', 'header']

  connect() {
    this.active = this.hasPopTarget && this.hasHeaderTarget;
  }

  show() {
    if (!this.active) return;

    const tooltipCalculations = new TooltipCalculations(this.popTarget, this.headerTarget);

    if (tooltipCalculations.isBelow())
      this.popBelow()
    else
      this.popAbove()

    this.setLeft(tooltipCalculations.getLeft())
    this.setTop(tooltipCalculations.getTop())
    this.setOffset(tooltipCalculations.getOffset())

    this.popTarget.classList.add('visible');
  }

  hide() {
    if (!this.active) return;

    this.popTarget.classList.remove('visible');
  }

  setLeft(value) {
    this.popTarget.style.left = `${value}px`
  }

  setTop(value) {
    this.popTarget.style.top = `${value}px`
  }

  setOffset(offset) {
    this.popTarget.style.setProperty('--offset', `${offset}px`)
  }

  popAbove() {
    this.popTarget.classList.remove('below');
  }

  popBelow() {
    this.popTarget.classList.add('below');
  }
}
