import * as Settings from './settings'
import _forEach from 'lodash/forEach'

export const config = () => {
  let config = Settings.config()

  _forEach(config.pages, (page) => {
    _forEach(page.elements, (element) => {
      if (element.type === 'datepicker') { element.type = 'text' }
      if (element.type === 'paneldynamic') {
        _forEach(element.templateElements, (panelElement) => {
          if (panelElement.type === 'datepicker') { panelElement.type = 'text' }
        })
      }
    })
  })

  return config
}
