import {Controller} from 'stimulus'
import Rails from '@rails/ujs'
import * as Survey from 'survey-jquery/survey.jquery.min'
import * as SurveyPDF from 'survey-pdf/survey.pdf.min'
import * as Settings from './settings'
import * as PdfSettings from './pdf_settings'
import _each from 'lodash/each'
import ExportHelper from './pdf_export_helper'
import 'jquery-ui-dist/jquery-ui.css'
import * as moment from 'moment'

import * as widgets from 'surveyjs-widgets'
import _forEach from 'lodash/forEach'

import serializedForms from "../../admin/serialized_forms";

export default class extends Controller {
  static get targets () {
    return ['survey', 'navigation', 'completeButton', 'nextButton', 'status', 'errors', 'input']
  }

  static values = {
    readOnly: Boolean
  }

  commonSetup() {
    this.opportunityId = this.element.dataset.opportunityId
    this.prospectName = this.element.dataset.prospectName

    let theme = Survey.StylesManager.ThemeColors['bootstrapmaterial']

    theme['$main-color'] = '#1C34EE'

    Survey.StylesManager.applyTheme('bootstrapmaterial')

    widgets.inputmask(Survey)
    widgets.jqueryuidatepicker(Survey)

    this.survey = new Survey.Model(Settings.config())
  }

  connect () {
    this.commonSetup()

    if(this.readOnlyValue){
      this.survey.mode = 'display'
    }

    this.getData()

    const _this = this
    this.survey
      .onComplete
      .add(function (sender) {
        _this.sendData(JSON.stringify(sender.data, null, 3))
      })

    this.survey.onCurrentPageChanged.add(function (sender, opt) {
      _this.renderNavigation()
      _this.sendData(JSON.stringify(_this.survey.data))
    })

    this.survey.onValidatedErrorsOnCurrentPage.add((sender, opt)=>{
      _this.showErrorMessage(opt.errors.length)
    })

    this.survey.onValueChanging.add((survey, options) => {
      if (options.question.getType() === 'datepicker' && options.question.inputType === 'date') {
        if(!options.question.isEmpty()) {
          options.value = ExportHelper.getTimezoneNeutralDate(new Date(options.value));
        }
      }
      if (options.question.getType() === 'paneldynamic') {
        _each(options.value, (element, i) => {
          _forEach(Object.keys(element), (key) => {
            if(element[key] !== options.oldValue[i][key]){
              if(moment(element[key], 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]', true).isValid()){
                element[key] = ExportHelper.getTimezoneNeutralDate(new Date(element[key]));
              }
            }
          })
        })
      }
    });

    this.survey.render(this.surveyTarget)
    this.progress = new Survey.SurveyProgressButtonsModel(this.survey)
    this.renderNavigation()
  }

  sendData (data) {
    let _this = this
    Rails.ajax({
      url: '/opportunities/' + this.opportunityId + '/questionnaire',
      type: 'post',
      beforeSend: (xhr, options) => {
        _this.startLoading()
        xhr.setRequestHeader('Content-Type', 'application/json')
        options.data = data
        return true
      },
      success: (data, status, xhr) => {
        _this.finishLoading()
      },
      error: (response, status, xhr) => {
        // handling signin timeout
        if(xhr.status === 401){
          window.location = window.location.href
        }
      }
    })
  }

  getData () {
    let _this = this
    Rails.ajax({
      url: '/opportunities/' + this.opportunityId + '/questionnaire',
      type: 'get',
      success: (data, status, xhr) => {
        _this.survey.data = data
        _this.renderNavigation()
      }
    })
  }

  submit(){
    window.location = `/opportunities/${this.opportunityId}/submit_questionnaire`
  }

  renderNavigation () {
    const nav = this.navigationTarget
    nav.innerHTML = ''
    _each(this.survey.visiblePages, (v, k) => {
      const surveyStepDiv = document.createElement('div')
      surveyStepDiv.classList.add('survey__step')
      if (v.passed || !v.hasErrors(false)) { surveyStepDiv.classList.add('is-complete')}
      if (v.isActive) {surveyStepDiv.classList.add('is-active')}

      const stepNumDiv = document.createElement('div')
      const stepNameP = document.createElement('p')
      stepNameP.innerHTML = v.title
      stepNumDiv.innerHTML = k + 1
      stepNumDiv.setAttribute('data-action', 'click->questionnaire--surveyjs#goToPage')
      stepNumDiv.setAttribute('data-page-id', k)
      surveyStepDiv.appendChild(stepNumDiv)
      surveyStepDiv.appendChild(stepNameP)
      nav.appendChild(surveyStepDiv)
    })

    if (!this.readOnlyValue) {
      if (this.survey.isLastPage) {
        $(this.completeButtonTarget).show()
        $(this.nextButtonTarget).hide()
      } else {
        $(this.completeButtonTarget).hide()
        $(this.nextButtonTarget).show()
      }
    }
  }

  nextPage () {
    serializedForms.resetAll()
    this.sendData(JSON.stringify(this.survey.data))
    this.survey.nextPage()
  }

  cancel () {
    window.location = '/'
  }

  saveAndExit () {
    serializedForms.resetAll()
    this.sendData(JSON.stringify(this.survey.data))
    window.location = '/'
  }

  complete () {
    if(this.survey.completeLastPage()){
      serializedForms.resetAll()
      this.submit()
    }
  }

  goToPage (event) {
    let pageNum = parseInt(event.target.dataset.pageId)
    if (this.progress.isListElementClickable(pageNum)) {
      serializedForms.resetAll()
      this.progress.clickListElement(pageNum)
    }
  }

  downloadSetup() {
    const options = {
      fontSize: 8,
      margins: {
        left: 10,
        right: 10,
        top: 10,
        bot: 10
      },
      format: [210, 297],
      textFieldRenderAs: 'multiLine'
    };
    let surveyPDF = new SurveyPDF.SurveyPDF(PdfSettings.config(), options);

    SurveyPDF.SurveyHelper.FORM_BORDER_VISIBLE = false;
    SurveyPDF.SurveyHelper.VALUE_READONLY_PADDING_SCALE = 0;
    SurveyPDF.SurveyHelper.MULTIPLETEXT_TEXT_PERS = 0.3;
    SurveyPDF.SurveyHelper.TITLE_PANEL_FONT_SIZE_SCALE = 1.4;
    SurveyPDF.SurveyHelper.TITLE_PAGE_FONT_SIZE_SCALE = 2.4
    SurveyPDF.SurveyHelper.TITLE_FONT_SCALE = 1.4;
    SurveyPDF.FlatQuestion.CONTENT_INDENT_SCALE = 0;
    SurveyPDF.FlatQuestion.CONTENT_GAP_VERT_SCALE = 0.2;
    SurveyPDF.SurveyHelper.TEXT_COLOR = 'black';

    surveyPDF.data = this.survey.data;
    surveyPDF.mode = 'display';
    surveyPDF.haveCommercialLicense = true;
    surveyPDF.requiredText = ''

    ExportHelper.useUsDateFormat(surveyPDF)

    surveyPDF.getAllQuestions().forEach(function(question) {
      ExportHelper.disableDescription(question)
      if (question.getType() === "checkbox") {
        ExportHelper.disableSelectAll(question)

        ExportHelper.disableOther(question)

        ExportHelper.disableNone(question)

        if(question.choicesFromQuestion) {
          const choices = surveyPDF.getQuestionByName(question.choicesFromQuestion).choices
          question.choices = ExportHelper.getSelectedChoices(question, choices)
          question.choicesFromQuestion = undefined
        }
        else
          question.choices = ExportHelper.getSelectedChoices(question, question.choices)
      }
    });

    const color = '#365a79'
    surveyPDF
      .onRenderQuestion
      .add((survey, options) => {
        let plainBricks = options.bricks[0].unfold();
        let line_rendered = false
        let header_rendered = false
        let first_position = null
        _each(plainBricks, (brick, index) => {
          if('text' in brick) {
            if(first_position === null)
              first_position = brick.yTop

            if(!header_rendered) {
              brick.textColor = color
              brick.color = color
            }
          }
          else if('value' in brick) {
            // Answers here
          }
          else if (!line_rendered) {
            if (first_position !== null)
              brick.yTop = first_position
            brick.textColor = color
            brick.color = color
            line_rendered = true
            header_rendered = true
          }
        })

        return new Promise(function (resolve) {
          resolve();
        });

      })

    return surveyPDF
  }

  downloadPDF() {
    const surveyPDF = this.downloadSetup()

    surveyPDF.save(`Questionnaire - ${this.prospectName}.pdf`);
  }

  disconnect () {
    // this.sendData(JSON.stringify(this.survey.data))
  }

  startLoading(){
    if(this.hasStatusTarget){
      this.statusTarget.innerHTML = 'Loading...'
    }
  }

  finishLoading(){
    if(this.hasStatusTarget){
      const time = new Date()
      this.statusTarget.innerHTML = `Last Saved: ${time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`
    }
  }

  showErrorMessage(errorsLength){
    this.errorsTarget.innerHTML = ''
    if(errorsLength !== 0){
      this.errorsTarget.innerHTML = 'There are some errors on this page'
    }
  }
}
