import { Controller } from 'stimulus';
import serializedForms from '../admin/serialized_forms'

export default class extends Controller {
  close(event){
    if(serializedForms.handleEvent(event)){
      $('.is-open').removeClass('is-open')
      $('html').removeClass('is-off')
    }

    event.preventDefault()
    return false;
  }
};
