import { Controller } from 'stimulus';

import BenefitComparison from '../src/benefit_comparisons'

export default class extends Controller {
  initialize () {
    this.comparison = new BenefitComparison(this.element.getAttribute('data-plan-type'))
  }

  update_plan(event){
    this.comparison.update_plan(event.target)
  }
}
