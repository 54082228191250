import { Controller } from 'stimulus';

export default class extends Controller {
  toggle(event){
    if (!event.target.closest('.js-nav-toggle')) return;
    event.preventDefault();

    let target = event.target.closest('.js-nav-toggle');

    target.parentNode.classList.toggle('is-open');
  }
};
