export const config = () => {
  return {
    'showProgressBar': 'off',
    'showCompletedPage': false,
    'showNavigationButtons': 'none',
    'showQuestionNumbers': 'off',
    'showPreviewBeforeComplete': 'showAllQuestions',
    'title': false,
    'logoPosition': 'none',
    'pages': [{
      'name': 'company_information',
      'elements': [{
        'type': 'text',
        'name': 'company_legal_name',
        'startWithNewLine': false,
        'title': 'Company legal name',
        'isRequired': true
      }, {
        'type': 'text',
        'name': 'dba',
        'startWithNewLine': false,
        'title': 'DBA - if applicable'
      }, {
        'type': 'multipletext',
        'name': 'address',
        'title': 'Address',
        'isRequired': true,
        'items': [
          {'name': 'address_line_1', 'title': 'Address Line 1'},
          {'name': 'address_line_2', 'title': 'Address Line 2'}, {
            'name': 'city',
            'title': 'City'
          }, {'name': 'state', 'title': 'State / Province / Region'}, {
            'name': 'zip_code',
            'title': 'ZIP / Postal Code'
          }]
      }, {'type': 'text', 'name': 'website', 'title': 'Website', 'isRequired': true}, {
        'type': 'text',
        'name': 'fein',
        'title': 'Federal Employer Identification Number (FEIN)',
        'description': 'Enter 9 digits with NO dash (-)',
        'isRequired': true,
        'validators': [{'type': 'numeric'}, {
          'type': 'regex',
          'text': 'Enter 9 digits with NO dash (-)',
          'regex': '^\\d{9}$'
        }],
        'inputType': 'number',
        'maxLength': 9
      }, {
        'type': 'dropdown',
        'name': 'state_of_incorporation',
        'title': 'State of incorporation',
        'isRequired': true,
        'choices': ['Alabama ', 'Alaska ', 'Arizona ', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'],
        'hasOther': true,
        'otherText': 'Other (describe)',
        'placeholder': 'Choose one'
      }, {
        'type': 'datepicker',
        'inputType': 'date',
        'dateFormat': 'mm/dd/yy',
        'name': 'date_of_incorporation',
        'title': 'Date of incorporation',
        'isRequired': true
      }, {
        'type': 'panel',
        'name': 'contact_information',
        'elements': [{
          'type': 'text',
          'name': 'contact_first_name',
          'minWidth': '100px',
          'maxWidth': '',
          'startWithNewLine': false,
          'title': 'First',
          'isRequired': true
        }, {
          'type': 'text',
          'name': 'contact_last_name',
          'startWithNewLine': false,
          'title': 'Last',
          'isRequired': true
        }, {
          'type': 'text',
          'name': 'contact_title',
          'startWithNewLine': false,
          'title': 'Title',
          'isRequired': true
        }, {
          'type': 'text',
          'name': 'contact_email',
          'startWithNewLine': false,
          'title': 'Email',
          'isRequired': true
        }, {'type': 'text', 'name': 'contact_phone', 'startWithNewLine': false, 'title': 'Phone'}],
        'title': 'Contact information'
      }, {
        'type': 'boolean',
        'name': 'were_you_referred',
        'title': 'Were you referred to us?',
        'isRequired': true
      }, {
        'type': 'text',
        'name': 'referral_company',
        'visible': false,
        'visibleIf': '{were_you_referred} = true',
        'title': 'Which agency/company referred you?',
        'isRequired': true
      }, {
        'type': 'text',
        'name': 'referral_person',
        'visible': false,
        'visibleIf': '{were_you_referred} = true',
        'startWithNewLine': false,
        'title': 'What is the person\'s name who referred you?',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'entities_boolean',
        'title': 'Are there additional entities with employees that are associated with the parent company?',
        'isRequired': true
      }, {
        'type': 'paneldynamic',
        'name': 'entities_list',
        'visible': false,
        'visibleIf': '{entities_boolean} = true',
        'title': 'Please list below each additional entities\' legal name, corresponding FEIN and percentage of common ownership to the main entity',
        'isRequired': true,
        'templateElements': [{
          'type': 'text',
          'name': 'entity_name',
          'title': 'Company Name',
          'isRequired': true
        }, {
          'type': 'text',
          'name': 'entity_fein',
          'startWithNewLine': false,
          'title': 'FEIN',
          'isRequired': true,
          'requiredErrorText': 'Enter 9 digits with NO dash (-)',
          'validators': [{'type': 'regex', 'regex': '^\\d{9}$'}]
        }, {
          'type': 'text',
          'inputMask': 'percentage',
          'name': 'entity_ownership',
          'startWithNewLine': false,
          'title': 'Percent of common ownership',
          'isRequired': true
        }],
        'panelCount': 1,
        'minPanelCount': 1,
        'panelAddText': 'Add new entity',
        'panelRemoveText': 'Remove entity'
      }],
      'title': 'Company Information'
    }, {
      'name': 'business_operations',
      'elements': [{
        'type': 'dropdown',
        'name': 'company_enitity_type',
        'title': 'What is your company entity type?',
        'isRequired': true,
        'choices': [{'value': 'llc', 'text': 'LLC'}, {'value': 'partnership', 'text': 'Partnership'}, {
          'value': 'c-corp',
          'text': 'C Corp'
        }, {'value': 's-corp', 'text': 'S Corp'}, {
          'value': 'non-profit',
          'text': 'Non-profit'
        }, {'value': 'sole-proprietor', 'text': 'Sole-proprietor'}],
        'placeholder': 'Choose one'
      }, {
        'type': 'paneldynamic',
        'name': 'ownership_details',
        'visibleIf': '{company_enitity_type} <> \'non-profit\'',
        'title': 'Ownership details',
        'isRequired': true,
        'templateElements': [{'type': 'text', 'name': 'owner_name', 'title': 'Owner\'s name'}, {
          'type': 'text',
          'inputMask': 'percentage',
          'name': 'owner_percentage',
          'startWithNewLine': false,
          'title': 'Percentage'
        }, {
          'type': 'radiogroup',
          'name': 'owner_peyroll',
          'startWithNewLine': false,
          'title': 'Will be on PEO payroll?\t',
          'choices': [{'value': 'item1', 'text': 'Yes'}, {'value': 'item2', 'text': ' No'}]
        }],
        'panelCount': 1,
        'minPanelCount': 1,
        'panelAddText': 'Add new owner'
      }, {
        'type': 'radiogroup',
        'name': 'members_paid_method',
        'visibleIf': '{company_enitity_type} = \'llc\' and {company_enitity_type} = \'s-corp\'',
        'title': 'How are members/partners/owners paid?',
        'choices': [{'value': 'k1', 'text': 'K1 distribution'}, {'value': 'w2', 'text': 'W2 wages'}, {
          'value': 'both',
          'text': 'Both'
        }]
      }, {
        'type': 'checkbox',
        'name': 'partners_are_paid_via_k1_only',
        'visibleIf': '{company_enitity_type} = \'partnership\'',
        'title': 'Please confirm that partners are only paid via K1 distribution',
        'isRequired': true,
        'choices': [{'value': 'item1', 'text': 'Confirm'}]
      }, {
        'type': 'boolean',
        'name': 'volunteers_boolean',
        'visibleIf': '{company_enitity_type} = \'non-profit\'',
        'title': 'Do you have volunteers?',
        'isRequired': true
      }, {
        'type': 'panel',
        'name': 'volunteers_panel',
        'elements': [{
          'type': 'text',
          'name': 'volunteers_number',
          'useDisplayValuesInTitle': false,
          'title': 'Approximately how many volunteers do you have annually?',
          'inputType': 'number',
          'size': 30
        }, {
          'type': 'comment',
          'name': 'volunteers_duties',
          'title': 'What are volunteers job duties performed?'
        }, {
          'type': 'boolean',
          'name': 'volunteers_payment',
          'title': 'Are volunteers receiving payment or other compensation?'
        }, {
          'type': 'boolean',
          'name': 'volunteers_waiver',
          'title': 'Do you have volunteers sign a waiver releasing your company of liability for injury?'
        }],
        'visibleIf': '{volunteers_boolean} = true',
        'title': 'Volunteers'
      }, {
        'type': 'text',
        'name': 'sic_code',
        'title': 'SIC code',
        'size': 30,
        'placeHolder': '4 digits'
      }, {
        'type': 'dropdown',
        'name': 'industry_type',
        'title': 'Industry type',
        'isRequired': true,
        'choices': [{
          'value': 'white_collar',
          'text': 'White collar (professional, managerial, or sales work is performed in an office, cubicle, or other administrative setting)'
        }, {
          'value': 'grey_collar',
          'text': 'Grey collar (employees of this type may be employed in the following industries; retail, restaurants/hospitality, technical, hospitals/nursing homes)'
        }, {
          'value': 'blue_collar',
          'text': 'Blue collar (some or all employees perform manual labor or operate machinery)'
        }],
        'hasOther': true,
        'placeholder': 'Choose one'
      }, {
        'type': 'dropdown',
        'name': 'company_status',
        'title': 'Company status',
        'isRequired': true,
        'choices': [{
          'value': 'startup',
          'text': 'Startup - has not yet run payroll nor has any internal infrastructure (i.e. payroll, benefits, workers compensation, etc.)'
        }, {
          'value': 'open_market',
          'text': 'Open Market - established company that is not currently using a PEO'
        }, {'value': 'peo_client', 'text': 'PEO Client - currently utilizing a PEO'}],
        'hasOther': true,
        'placeholder': 'Choose one'
      }, {
        'type': 'text',
        'name': 'full_time_employees_number',
        'visibleIf': '{company_status} <> \'startup\'',
        'maxWidth': '120px',
        'title': 'Full-time employees?',
        'isRequired': true,
        'inputType': 'number'
      }, {
        'type': 'text',
        'name': 'part_time_employees_number',
        'visibleIf': '{company_status} <> \'startup\'',
        'maxWidth': '120px',
        'startWithNewLine': false,
        'title': 'Part-time employees?',
        'isRequired': true,
        'inputType': 'number'
      }, {
        'type': 'text',
        'name': 'first_payroll_employees_number',
        'visibleIf': '{company_status} = \'startup\'',
        'title': 'How many employees will you have at first payroll?',
        'isRequired': true,
        'inputType': 'number'
      }, {
        'type': 'boolean',
        'name': 'union_employees_boolean',
        'visibleIf': '{industry_type} = \'grey_collar\' and {industry_type} = \'blue_collar\'',
        'title': 'Union employees?',
        'isRequired': true
      }, {
        'type': 'text',
        'name': 'union_employess_number',
        'visibleIf': '{union_employees_boolean} = true',
        'title': 'How many union employees?',
        'isRequired': true,
        'inputType': 'number'
      }, {
        'type': 'comment',
        'name': 'company_operations',
        'title': 'Please provide a description of your company\'s operations',
        'isRequired': true
      }, {
        'type': 'dropdown',
        'name': 'current_payroll_provider',
        'visibleIf': '{company_status} = \'open_market\'',
        'title': 'Current payroll provider',
        'isRequired': true,
        'choices': [{'value': 'adp', 'text': 'ADP payroll'}, {
          'value': 'paychex',
          'text': 'Paychex payroll'
        }, {'value': 'ceridian', 'text': 'Ceridian'}, {'value': 'paycom', 'text': 'Paycom'}, {
          'value': 'zen',
          'text': 'Zen payroll'
        }, {'value': 'in-house', 'text': 'In-house'}],
        'hasOther': true,
        'placeholder': 'Choose one'
      }, {
        'type': 'text',
        'inputMask': 'currency',
        'prefix': '$',
        'name': 'payroll_fees',
        'size': 30,
        'visible': false,
        'visibleIf': '{company_status} = \'open_market\' and {current_payroll_provider} <> \'in-house\'',
        'title': 'Annual fees - Payroll',
        'description': 'Please indicate the amount of fees paid ANNUALLY to your payroll provider',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'changing_payroll_provider_boolean',
        'visibleIf': '{company_status} = \'open_market\'',
        'title': 'Are you willing to change your payroll provider?',
        'isRequired': true
      }, {
        'type': 'dropdown',
        'name': 'current_compensation_insurance_carrier',
        'visibleIf': '{company_status} = \'open_market\'',
        'title': 'Who is your current workers\' compensation insurance carrier?',
        'isRequired': true,
        'choices': [{'value': 'aig', 'text': 'AIG'}, {'value': 'chubb', 'text': 'Chubb'}, {
          'value': 'cna',
          'text': 'CNA'
        }, {'value': 'liberty_mutual', 'text': 'Liberty Mutual'}, {
          'value': 'state',
          'text': 'State Insurance Fund'
        }, {'value': 'hartford', 'text': 'The Hartford'}, {'value': 'travelers', 'text': 'Travelers'}],
        'hasOther': true,
        'placeholder': 'Choose one',
        'size': 30
      }, {
        'type': 'dropdown',
        'name': 'Current_PEO',
        'visibleIf': '{company_status} = \'peo_client\'',
        'title': 'Current PEO',
        'isRequired': true,
        'choices': ['ADP TotalSource', 'Alcott', 'CoAdvantage', 'Engage', 'Extensis', 'Insperity', 'Justworks', 'Oasis', 'Prestige', 'TriNet', 'Paychex'],
        'hasOther': true
      }, {
        'type': 'dropdown',
        'name': 'payroll_frequency',
        'visibleIf': '{company_status} = \'open_market\' or {company_status} = \'peo_client\'',
        'title': 'What is your payroll frequency?',
        'isRequired': true,
        'choices': [{'value': 'bi-weekly', 'text': 'Bi-weekly (26 pay periods)'}, {
          'value': 'semi-monthly',
          'text': 'Semi-monthly (24 pay periods)'
        }, {'value': 'weekly', 'text': 'Weekly (52 pay periods)'}, {
          'value': 'monthly',
          'text': 'Monthly (12 pay periods)'
        }, {'value': 'multiple', 'text': 'Multiple frequencies'}],
        'hasOther': true,
        'placeholder': 'Choose one',
        'size': 30
      }, {
        'type': 'dropdown',
        'name': 'anticipated_payroll_frequency',
        'visibleIf': '{company_status} = \'startup\'',
        'title': 'What do you anticipate will be your payroll frequency?',
        'isRequired': true,
        'choices': [{'value': 'bi-weekly', 'text': 'Bi-weekly (26 pay periods)'}, {
          'value': 'semi-monthly',
          'text': 'Semi-monthly (24 pay periods)'
        }, {'value': 'weekly', 'text': 'Weekly (52 pay periods)'}, {
          'value': 'monthly',
          'text': 'Monthly (12 pay periods)'
        }, {'value': 'multiple', 'text': 'Multiple frequencies'}],
        'hasOther': true,
        'placeholder': 'Choose one',
        'size': 30
      }, {
        'type': 'datepicker',
        'inputType': 'date',
        'dateFormat': 'mm/dd/yy',
        'name': 'current_pay_period_start',
        'visibleIf': '{company_status} <> \'startup\'',
        'title': 'When is your current pay period start date?',
        'isRequired': true
      }, {
        'type': 'datepicker',
        'inputType': 'date',
        'dateFormat': 'mm/dd/yy',
        'name': 'current_pay_period_end_date',
        'visibleIf': '{company_status} <> \'startup\'',
        'title': 'When is your current pay period end date?',
        'isRequired': true
      }, {
        'type': 'datepicker',
        'inputType': 'date',
        'dateFormat': 'mm/dd/yy',
        'name': 'next_scheduled_check_date',
        'visibleIf': '{company_status} <> \'startup\'',
        'title': 'When is your next scheduled check date?',
        'isRequired': true
      }, {
        'type': 'datepicker',
        'inputType': 'date',
        'dateFormat': 'mm/dd/yy',
        'name': 'target_date_first_payroll',
        'visibleIf': '{company_status} = \'startup\'',
        'title': 'What is your target date for first payroll?',
        'isRequired': true
      }, {
        'type': 'datepicker',
        'inputType': 'date',
        'dateFormat': 'mm/dd/yy',
        'name': 'target_date_starting_with_PEO',
        'title': 'Ideally, what would be your target date for starting with a PEO?',
        'isRequired': true
      }, {
        'type': 'checkbox',
        'name': 'Additional_employee_wages',
        'visibleIf': '{Current_PEO} = \'ADP TotalSource\'',
        'title': 'Additional employee wages',
        'description': 'Check all that apply',
        'isRequired': true,
        'choices': ['We do not pay bonuses', 'We do not pay commission', 'We have one bonus payment per year', 'We pay multiple bonuses throughout the year', 'We pay commissions included with regular payroll', 'Commissions are paid off regular payroll cycle'],
        'hasOther': true,
        'colCount': 2
      }, {
        'type': 'boolean',
        'name': 'Have you received a proposal from a PEO within the last 6 months?',
        'isRequired': true
      }, {
        'type': 'checkbox',
        'name': 'PEO_received_proposal_v2',
        'visible': false,
        'visibleIf': '{Have you received a proposal from a PEO within the last 6 months?} = true',
        'requiredIf': '{Have you received a proposal from a PEO within the last 6 months?} = true',
        'title': 'Please choose all PEO(s) from which you\'ve received a proposal',
        'description': 'Select all that apply',
        'choices': ['ADP TotalSource', 'Alcott', 'CoAdvantage', 'Engage', 'Extensis', 'Insperity', 'Justworks', 'Oasis', 'Prestige', 'TriNet', 'Paychex'],
        'hasOther': true,
        'colCount': 3,
        'hasSelectAll': true
      }, {
        'type': 'boolean',
        'name': 'PEOs_like_proposal',
        'title': 'Are there any PEOs from which you\'d like to see a proposal?',
        'isRequired': true
      }, {
        'type': 'comment',
        'name': 'which_PEO_see_proposal',
        'visibleIf': '{PEOs_like_proposal} = true',
        'title': 'Please indicate from which PEO(s) you\'d like to see a proposal',
        'isRequired': true
      }, {'type': 'boolean', 'name': 'Do you use 1099 / contractors?', 'isRequired': true}, {
        'type': 'boolean',
        'name': 'collect_COI_1099_contractors',
        'visibleIf': '{Do you use 1099 / contractors?} = true',
        'title': 'Do you collect Certificates of Insurance (COI) on all 1099 / contractors?',
        'isRequired': true
      }, {
        'type': 'text',
        'name': 'how_many_1099s',
        'visibleIf': '{Do you use 1099 / contractors?} = true',
        'title': 'Approximately how many 1099s / contractors do you employ annually?',
        'isRequired': true,
        'inputType': 'number',
        'size': 30
      }, {
        'type': 'comment',
        'name': 'type_work_contractors_perform',
        'visibleIf': '{Do you use 1099 / contractors?} = true',
        'title': 'Please describe the type of work that all contractors perform for your company',
        'isRequired': true
      }, {
        'type': 'radiogroup',
        'name': 'annual_employee_turnover',
        'visibleIf': '{company_status} = \'open_market\'',
        'title': 'Which best describes your annual employee turnover',
        'isRequired': true,
        'choices': ['Low - under 10%', 'Moderate - 10 - 20%', 'High - more than 20%'],
        'hasOther': true
      }, {
        'type': 'boolean',
        'name': 'anticipate_headcount_growth over the next 24 months?',
        'visibleIf': '{company_status} = \'startup\' or {company_status} = \'open_market\'',
        'title': 'Do you anticipate headcount growth over the next 24 months?\n',
        'isRequired': true
      }, {
        'type': 'radiogroup',
        'name': 'anticipate_your_headcount_growth',
        'visibleIf': '{anticipate_headcount_growth over the next 24 months?} = true',
        'title': 'What do you anticipate your headcount growth to be over this period?',
        'isRequired': true,
        'choices': ['Low - under 10%', 'Moderate - 10 - 20%', 'High - more than 20%'],
        'hasOther': true
      }, {
        'type': 'text',
        'name': 'number_office_locations',
        'visibleIf': '{company_status} <> \'startup\'',
        'title': 'Please indicate the number of office locations (including HQ)',
        'isRequired': true,
        'inputType': 'number',
        'size': 30
      }, {
        'type': 'checkbox',
        'name': 'State_your_employees_WORK',
        'visibleIf': '{company_status} <> \'startup\'',
        'title': 'State(s) in which your employees WORK (please choose all that apply)',
        'isRequired': true,
        'choicesFromQuestion': 'state_of_incorporation',
        'choices': ['item1'],
        'hasOther': true,
        'colCount': 3
      }, {
        'type': 'boolean',
        'name': 'employees_live_internationally',
        'visibleIf': '{company_status} <> \'startup\'',
        'requiredIf': '{company_status} <> \'startup\'',
        'title': 'Do you have employees that live and work internationally?'
      }, {
        'type': 'text',
        'name': 'How_many_employees_internationally',
        'visibleIf': '{employees_live_internationally} = true',
        'requiredIf': '{employees_live_internationally} = true',
        'title': 'How many employees work internationally?',
        'inputType': 'number',
        'size': 30
      }, {
        'type': 'boolean',
        'name': 'need_international_payroll',
        'visibleIf': '{employees_live_internationally} = true',
        'title': 'Do you have a need for an international payroll solution?'
      }, {
        'type': 'boolean',
        'name': 'employ_foreign_nationals',
        'visibleIf': '{company_status} <> \'startup\'',
        'title': 'Do you employ foreign nationals?',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'employees_travel_foreign_countries',
        'title': 'Will any of your employees travel to foreign countries for business purposes?',
        'isRequired': true
      }, {
        'type': 'text',
        'name': 'Total_employees_travel_foreign_countries',
        'visibleIf': '{employees_travel_foreign_countries} = true',
        'title': 'Total number of employees who will travel to foreign countries this year?',
        'isRequired': true,
        'inputType': 'number',
        'size': 30
      }, {
        'type': 'text',
        'name': 'average_trip_duration',
        'visibleIf': '{employees_travel_foreign_countries} = true',
        'title': 'What is the average maximum trip duration in days?',
        'isRequired': true,
        'inputType': 'number'
      }, {
        'type': 'comment',
        'name': 'frequency_foreign_travel',
        'visibleIf': '{employees_travel_foreign_countries} = true',
        'title': 'What is the frequency of foreign travel?',
        'description': 'How many times per year will employees travel to foreign countries.',
        'isRequired': true
      }, {
        'type': 'comment',
        'name': 'countries_employees_travel',
        'visibleIf': '{employees_travel_foreign_countries} = true',
        'title': 'Please list the countries to which employees will travel',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'hiring_non_citizens',
        'title': 'Will you be hiring any non-US citizens (i.e. foreign local nationals) for work performed in a foreign country?',
        'isRequired': true
      }],
      'title': 'Business Operations'
    }, {
      'name': 'employee_benefits',
      'elements': [{
        'type': 'boolean',
        'name': 'currently_offer_medical_benefits',
        'visibleIf': '{company_status} <> \'startup\'',
        'title': 'Do you currently offer medical benefits to your employees?',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'interested_medical_employees_through_peo',
        'visible': false,
        'visibleIf': '{company_status} = \'startup\' or {currently_offer_medical_benefits} = false',
        'title': 'Are you interested in providing medical insurance to your employees through a PEO?',
        'isRequired': true
      }, {
        'type': 'text',
        'name': 'employees_currently_participate_medical',
        'visibleIf': '{currently_offer_medical_benefits} = true',
        'title': 'How many employees currently participate in medical plan?',
        'isRequired': true,
        'inputType': 'number',
        'size': 30
      }, {
        'type': 'dropdown',
        'name': 'new_hire_waiting_period',
        'visibleIf': '{currently_offer_medical_benefits} = true',
        'title': 'What is the new-hire waiting period for enrollment in benefits?',
        'isRequired': true,
        'choices': ['Date-of-hire', '1st of the month after date-of-hire', '1st of the month after 30 days', '1st of the month after 60 days', '1st of the month after 90 days'],
        'hasOther': true
      }, {
        'type': 'dropdown',
        'name': 'employer_contribution',
        'visibleIf': '{currently_offer_medical_benefits} = true',
        'title': 'Employer contribution towards the lowest cost employee-only plan',
        'isRequired': true,
        'choices': ['N/A', '50%', '55%', '60%', '65%', '70%', '75%', '80%', '85%', '90%', '95%', '100%'],
        'hasOther': true
      }, {
        'type': 'dropdown',
        'name': 'contribute_employee_medical',
        'visible': false,
        'visibleIf': '{interested_medical_employees_through_peo} = true',
        'title': 'How much would you like to contribute towards an employee-only medical insurance plan?',
        'isRequired': true,
        'choices': ['N/A', '50%', '55%', '60%', '65%', '70%', '75%', '80%', '85%', '90%', '95%', '100%'],
        'hasOther': true
      }, {
        'type': 'boolean',
        'name': 'cover_retirees_medical',
        'visible': false,
        'visibleIf': '{company_status} = \'open_market\' or {currently_offer_medical_benefits} = true',
        'title': 'Do you cover retirees on your medical plan?',
        'isRequired': true
      }, {
        'type': 'dropdown',
        'name': 'health_insurance_renew',
        'visible': false,
        'visibleIf': '{currently_offer_medical_benefits} = true',
        'title': 'In which month does your health insurance renew?',
        'isRequired': true,
        'choices': ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      }, {
        'type': 'dropdown',
        'name': 'health_insurance_carrier',
        'visibleIf': '{currently_offer_medical_benefits} = true',
        'title': 'Who is your current health insurance carrier?',
        'isRequired': true,
        'choices': ['United/Oxford', 'Blue Cross/Blue Shield', 'Aetna', 'Cigna', 'Emblem', 'Oscar', 'MVP', 'Humana', 'N/A'],
        'hasOther': true
      }, {
        'type': 'checkbox',
        'name': 'medical_carrier_consider_using',
        'visibleIf': '{interested_medical_employees_through_peo} <> false',
        'title': 'Which medical carrier(s) would you consider using (choose all that apply)?',
        'isRequired': true,
        'choicesFromQuestion': 'health_insurance_carrier',
        'choices': ['item1', 'item2', 'item3'],
        'separateSpecialChoices': true,
        'hasOther': true,
        'colCount': 3,
        'hasSelectAll': true
      }, {
        'type': 'checkbox',
        'name': 'choose_following_products_company_uses',
        'visible': false,
        'visibleIf': '{company_status} = \'open_market\'',
        'title': 'Please choose any of the following products that your company CURRENTLY HAS in place:',
        'isRequired': true,
        'choices': ['Dental', 'Vision', 'Life insurance', 'Short-term disability', 'Long-term disability', '401(k) / retirement plan', 'Flexible spending account (FSA)', 'Health savings account (HSA)', 'Health reimbursement (HRA)', 'Commuter benefit', 'Employment practices liability (EPLI)', 'Employee assistance program (EAP)'],
        'separateSpecialChoices': true,
        'hasOther': true,
        'colCount': 3,
        'hasSelectAll': true
      }, {
        'type': 'checkbox',
        'name': 'products_company_would_like',
        'title': 'Please choose any of the following products that your company WOULD LIKE to put in place:',
        'isRequired': true,
        'choices': ['Dental', 'Vision', 'Life insurance', 'Short-term disability', 'Long-term disability', '401(k) ', 'Flexible spending account (FSA)', 'Health savings account (HSA)', 'Health reimbursement (HRA)', 'Commuter benefit', 'Employment practices liability (EPLI)', 'Employee assistance program (EAP)'],
        'separateSpecialChoices': true,
        'hasOther': true,
        'colCount': 3,
        'hasSelectAll': true
      }, {
        'type': 'dropdown',
        'name': 'dental_insurance',
        'visibleIf': '{choose_following_products_company_uses} contains \'Dental\'',
        'title': 'Who is your Dental insurance carrier?',
        'isRequired': true,
        'choices': ['Aetna', 'Blue Cross/Blue Shield', 'Cigna', 'Delta', 'Guardian', 'MetLife', 'Principal', 'UHC'],
        'separateSpecialChoices': true,
        'hasOther': true
      }, {
        'type': 'dropdown',
        'name': 'vision_carrier',
        'visibleIf': '{choose_following_products_company_uses} contains \'Vision\'',
        'title': 'Who is your Vision insurance carrier?',
        'isRequired': true,
        'choices': ['Aetna', 'Blue Cross / Blue Shield', 'EyeMed', 'Cigna', 'Guardian', 'MetLife', 'VSP', 'UHC'],
        'separateSpecialChoices': true,
        'hasOther': true
      }, {
        'type': 'boolean',
        'name': 'company_contribute_dental_vision',
        'visibleIf': '{choose_following_products_company_uses} anyof [\'Vision\', \'Dental\']',
        'title': 'Does the company contribute to the dental and/or vision coverage?',
        'isRequired': true
      }, {
        'type': 'comment',
        'name': 'describe_dental_vision_contribution_strategy',
        'visibleIf': '{company_contribute_dental_vision} = true',
        'title': 'Please describe your dental and/or vision contribution strategy',
        'isRequired': true,
        'placeHolder': 'Example:  Company contributes 50% to all tiers for dental. '
      }, {
        'type': 'text',
        'name': 'annual_HRA_costs',
        'visibleIf': '{choose_following_products_company_uses} contains \'Health reimbursement (HRA)\'',
        'title': 'How much are your annual HRA administration costs?',
        'isRequired': true,
        'inputMask': 'currency',
        'prefix': '$',
        'size': 30
      }, {
        'type': 'comment',
        'name': 'hra_funding_strategy',
        'visibleIf': '{choose_following_products_company_uses} contains \'Health reimbursement (HRA)\'',
        'title': 'Please describe your HRA funding strategy',
        'isRequired': true,
        'placeHolder': 'Example:  Company covers the first $1,000 of the base plan\'s deductible. '
      }, {
        'type': 'text',
        'name': 'annual_hsa_administration_costs',
        'visibleIf': '{choose_following_products_company_uses} contains \'Health savings account (HSA)\'',
        'title': 'How much are your annual HSA administration costs?',
        'isRequired': true,
        'inputMask': 'currency',
        'prefix': '$',
        'size': 30
      }, {
        'type': 'boolean',
        'name': 'fund_portion_of_hsa',
        'visibleIf': '{choose_following_products_company_uses} contains \'Health savings account (HSA)\'',
        'title': 'Do you fund any portion of the HSA?',
        'isRequired': true
      }, {
        'type': 'text',
        'name': 'how_much_HSA_company_fund',
        'visibleIf': '{fund_portion_of_hsa} = true',
        'title': 'How much of the HSA does the company fund?',
        'isRequired': true,
        'inputMask': 'currency',
        'prefix': '$',
        'size': 30
      }, {
        'type': 'text',
        'name': 'annual_commuter_benefits_fees',
        'visibleIf': '{choose_following_products_company_uses} contains \'Commuter benefit\'',
        'title': 'How much are your annual commuter benefits fees?',
        'isRequired': true,
        'inputMask': 'currency',
        'prefix': '$',
        'size': 30
      }, {
        'type': 'text',
        'name': 'annual_epli_premium',
        'visibleIf': '{choose_following_products_company_uses} contains \'Employment practices liability (EPLI)\'',
        'title': 'How much is your annual EPLI premium?',
        'isRequired': true,
        'inputMask': 'currency',
        'prefix': '$',
        'size': 30
      }, {
        'type': 'boolean',
        'name': '401k_plan_in_place',
        'visibleIf': '{company_status} = \'peo_client\'',
        'title': 'Is there a 401k plan currently in place?',
        'isRequired': true
      }, {
        'type': 'radiogroup',
        'name': 'current_401k_platform',
        'visibleIf': '{401k_plan_in_place} = true',
        'title': 'Which 401k platform is being used?',
        'isRequired': true,
        'choices': ['PEO\'s plan', 'Third-party']
      }, {
        'type': 'text',
        'name': '401k_plan_with',
        'visible': false,
        'visibleIf': '{current_401k_platform} = \'Third-party\' or {choose_following_products_company_uses} = [\'401(k) / retirement plan\']',
        'title': 'Who is your 401k plan with?',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'interested_evaluating_PEO_401k',
        'visible': false,
        'visibleIf': '{choose_following_products_company_uses} = [\'401(k) / retirement plan\'] or {current_401k_platform} = \'Third-party\'',
        'title': 'Are you interested in evaluating PEO 401(k) plan options?',
        'isRequired': true
      }, {
        'type': 'text',
        'name': 'annual_401(k)_costs',
        'visible': false,
        'visibleIf': '{interested_evaluating_PEO_401k} = true',
        'title': 'How much are your annual 401(k) plan costs?',
        'isRequired': true,
        'inputMask': 'currency',
        'prefix': '$',
        'size': 30
      }],
      'title': 'Employee Benefits'
    }, {
      'name': 'systems_technology',
      'elements': [{
        'type': 'checkbox',
        'name': 'choose_solutions_business_currently_uses',
        'visible': false,
        'visibleIf': '{company_status} = \'open_market\' or {company_status} = \'peo_client\'',
        'title': 'Please choose any of the following solutions your business currently has in place:',
        'isRequired': true,
        'choices': ['Online benefits enrollment platform', 'Paid-time-off tracking', 'Time & attendance', 'Expense Management', 'Human Resource Information System (HRIS)', 'Applicant Tracking System'],
        'separateSpecialChoices': true,
        'hasOther': true,
        'hasNone': true,
        'hasSelectAll': true
      }, {
        'type': 'checkbox',
        'name': 'choose_following_solutions_interest',
        'title': 'Please choose any or all of the following solutions that are of interest to your business',
        'description': 'Do not choose any solutions that you currently have in place',
        'isRequired': true,
        'choices': ['Online benefits enrollment platform', 'Paid-time-off tracking', 'Time & attendance', 'Expense Management', 'Human Resource Information System (HRIS)', 'Applicant Tracking System'],
        'separateSpecialChoices': true,
        'hasOther': true,
        'hasNone': true,
        'hasSelectAll': true
      }, {
        'type': 'radiogroup',
        'name': 'best_describes_HR_reporting_requirements',
        'visibleIf': '{company_status} <> \'startup\'',
        'title': 'Which of the following best describes your HR reporting requirements',
        'isRequired': true,
        'choices': ['None or very little', 'Standard reporting', 'Complex and customized reports required']
      }, {
        'type': 'text',
        'name': 'online_benefits_enrollment_system',
        'visible': false,
        'visibleIf': '{choose_solutions_business_currently_uses} contains \'Online benefits enrollment platform\'',
        'title': 'Which online benefits enrollment system is used?',
        'isRequired': true
      }, {
        'type': 'text',
        'name': 'fees_online_benefits_enrollment_platform',
        'visible': false,
        'visibleIf': '{choose_solutions_business_currently_uses} contains \'Online benefits enrollment platform\'',
        'title': 'Annual fees - Online benefits enrollment platform',
        'description': 'Please indicate the total amount of fees paid ANNUALLY for your online benefits enrollment platform',
        'inputMask': 'currency',
        'prefix': '$'
      }, {
        'type': 'dropdown',
        'name': 'time_attendance_system_use',
        'visible': false,
        'visibleIf': '{choose_solutions_business_currently_uses} contains \'Time & attendance\'',
        'title': 'Which type of Time & Attendance system do you use?',
        'isRequired': true,
        'choices': ['In-house tracking (ex. spreadsheet)', 'Third-party vendor'],
        'hasOther': true
      }, {
        'type': 'text',
        'name': 'name_of_T&A_system',
        'visible': false,
        'visibleIf': '{time_attendance_system_use} = \'Third-party vendor\'',
        'title': 'What is the name of the Time & Attendance system used?',
        'isRequired': true
      }, {
        'type': 'text',
        'name': 'annual_fees_t&a',
        'visible': false,
        'visibleIf': '{time_attendance_system_use} = \'Third-party vendor\' or {time_attendance_system_use} = \'other\'',
        'title': 'Annual fees - Time & Attendance',
        'description': 'Please indicate the total amount of fees paid ANNUALLY for your Time & Attendance system',
        'inputMask': 'currency',
        'prefix': '$'
      }, {
        'type': 'dropdown',
        'name': 'manage_expense_reimbursements',
        'visible': false,
        'visibleIf': '{choose_solutions_business_currently_uses} contains \'Expense Management\'',
        'title': 'How do you manage expense reimbursements?',
        'isRequired': true,
        'choices': ['Concur', 'Quickbooks', 'TriNet Expense', 'Payroll provider', 'Excel'],
        'hasOther': true
      }, {
        'type': 'text',
        'name': 'annual_fees_expense_management',
        'visible': false,
        'visibleIf': '{manage_expense_reimbursements} anyof [\'Payroll provider\', \'TriNet Expense\', \'Quickbooks\', \'Concur\', \'other\']',
        'title': 'Annual fees - Expense Management',
        'description': 'Please indicate the total amount of fees paid ANNUALLY for your Expense Management system',
        'inputMask': 'currency',
        'prefix': '$'
      }, {
        'type': 'text',
        'name': 'name_of_hris_platform',
        'visible': false,
        'visibleIf': '{choose_solutions_business_currently_uses} contains \'Human Resource Information System (HRIS)\'',
        'title': 'Please indicate the name of the HRIS platform used',
        'isRequired': true
      }, {
        'type': 'text',
        'name': 'annual_fees_hris',
        'visible': false,
        'visibleIf': '{choose_solutions_business_currently_uses} contains \'Human Resource Information System (HRIS)\'',
        'title': 'Annual fees - HR Information System',
        'description': 'Please indicate the total amount of fees paid ANNUALLY for your HRIS',
        'inputMask': 'currency',
        'prefix': '$'
      }, {
        'type': 'text',
        'name': 'name_applicant_tracking',
        'visible': false,
        'visibleIf': '{choose_solutions_business_currently_uses} contains \'Applicant Tracking System\'',
        'title': 'Please indicate the name of the Applicant Tracking system used',
        'isRequired': true
      }, {
        'type': 'text',
        'name': 'fees_applicant_tracking',
        'visible': false,
        'visibleIf': '{choose_solutions_business_currently_uses} contains \'Applicant Tracking System\'',
        'title': 'Annual fees - Applicant Tracking System',
        'description': 'Please indicate the total amount of fees paid ANNUALLY for your Applicant Tracking system',
        'inputMask': 'currency',
        'prefix': '$'
      }, {
        'type': 'checkbox',
        'name': 'choose_following_important_factors_using_peo',
        'visible': false,
        'visibleIf': '{company_status} = \'startup\' or {company_status} = \'open_market\'',
        'title': 'Please choose any/all of the following that are important factors in considering using a PEO',
        'choices': ['Manage future growth and employee headcount', 'Compliance guidance', 'Improved benefits offering', 'Manage risk/liability and protect assets', 'Becoming/remaining an \'employer of choice\'', 'Focus on core competencies', 'Reduce administrative burdens'],
        'separateSpecialChoices': true,
        'hasOther': true,
        'hasSelectAll': true
      }, {
        'type': 'checkbox',
        'name': 'following_important_considering_switching_peo',
        'visible': false,
        'visibleIf': '{company_status} = \'peo_client\'',
        'title': 'Please choose any/all of the following that are important factors in considering switching to another PEO',
        'choices': ['Improve customer service', 'Access to different insurance carriers', 'Enhanced benefits offering', 'Improve technology platform', 'Reduce benefit costs', 'Lower service fees', 'Transparent billing'],
        'hasOther': true,
        'hasSelectAll': true
      }],
      'title': 'Systems & Technology'
    }, {
      'name': 'risk_questions',
      'elements': [{
        'type': 'boolean',
        'name': 'company_drug_free_workplace',
        'title': 'Does your company have a Drug Free Workplace program?',
        'isRequired': true
      }, {
        'type': 'checkbox',
        'name': 'drug_testing_required',
        'visible': false,
        'visibleIf': '{company_drug_free_workplace} = true',
        'title': 'When is drug testing required?',
        'isRequired': true,
        'choices': ['Post-accident', 'Random', 'Pre-employment', 'Reasonable suspicion'],
        'hasOther': true,
        'colCount': 2
      }, {
        'type': 'boolean',
        'name': 'conduct_work_water_barges',
        'title': 'Do you conduct any work in / on / around / over water, barges, vessels, docks, or bridges?',
        'isRequired': true
      }, {
        'type': 'comment',
        'name': 'describe_work_performed',
        'visible': false,
        'visibleIf': '{conduct_work_water_barges} = true',
        'title': 'Please describe in more detail the work being performed',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'work_performed_underground',
        'title': 'Any work being performed underground or higher than 10 feet above ground level?',
        'isRequired': true
      }, {
        'type': 'text',
        'name': 'maximum_depth_height',
        'visible': false,
        'visibleIf': '{work_performed_underground} = true',
        'title': 'What is the maximum depth/height at which work is performed?',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'manual_handling_products',
        'title': 'Is there manual handling of products?',
        'isRequired': true
      }, {
        'type': 'text',
        'name': 'maximum_weight_handled',
        'visible': false,
        'visibleIf': '{manual_handling_products} = true',
        'title': 'Maximum weight handled',
        'isRequired': true
      }, {
        'type': 'text',
        'name': 'Who_packs_delivers_goods',
        'visible': false,
        'visibleIf': '{manual_handling_products} = true',
        'title': 'Who packs and delivers goods?',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'use_subcontractors',
        'title': 'Does the company use subcontractors?',
        'isRequired': true
      }, {
        'type': 'text',
        'inputMask': 'percentage',
        'name': 'percentage_contracted_out',
        'visible': false,
        'visibleIf': '{use_subcontractors} = true',
        'title': 'What percentage of work is contracted out?',
        'isRequired': true
      }, {
        'type': 'text',
        'name': 'How_many_unique_vendors',
        'visible': false,
        'visibleIf': '{use_subcontractors} = true',
        'title': 'How many unique vendors / contractors?',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'certificate_verification_program',
        'visible': false,
        'visibleIf': '{use_subcontractors} = true',
        'title': 'Do you have a certificate verification program in place?',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'wc_coverage_declined',
        'title': 'Have you had workers\' compensation coverage declined, canceled or non-renewed in the past three (3) years?',
        'isRequired': true
      }, {
        'type': 'comment',
        'name': 'reason_wc_cancelled',
        'visible': false,
        'visibleIf': '{wc_coverage_declined} = true',
        'title': 'Please specify the reason for the cancellation or declination',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'employees_drive_vehicles',
        'title': 'Do employees drive vehicles to conduct company business?',
        'isRequired': true
      }, {
        'type': 'text',
        'name': 'number_of_drivers',
        'visible': false,
        'visibleIf': '{employees_drive_vehicles} = true',
        'title': 'Number of drivers?',
        'isRequired': true
      }, {
        'type': 'comment',
        'name': 'types_vehicles_driven',
        'visible': false,
        'visibleIf': '{employees_drive_vehicles} = true',
        'title': 'Which types of vehicles are driven?',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'vehicles_require_commercial_license',
        'visible': false,
        'visibleIf': '{employees_drive_vehicles} = true',
        'title': 'Do any of the vehicles require a commercial drivers license?',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'overnight_hauling_performed',
        'visible': false,
        'visibleIf': '{employees_drive_vehicles} = true',
        'title': 'Is any overnight hauling performed?',
        'isRequired': true
      }, {
        'type': 'text',
        'name': 'radius_of_travel',
        'visible': false,
        'visibleIf': '{employees_drive_vehicles} = true',
        'title': 'What is the radius of travel using company vehicles?',
        'isRequired': true
      }, {
        'type': 'text',
        'name': 'frequency_of_trips',
        'visible': false,
        'visibleIf': '{employees_drive_vehicles} = true',
        'title': 'What is the frequency of trips?',
        'isRequired': true
      }, {
        'type': 'text',
        'name': 'vehicles_used',
        'visible': false,
        'visibleIf': '{employees_drive_vehicles} = true',
        'title': 'How many vehicles are used?',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'equity_in_merchandise_hauled',
        'visible': false,
        'visibleIf': '{employees_drive_vehicles} = true',
        'title': 'Does the company have any equity in the merchandise being hauled?',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'vehicles_company_owned',
        'visible': false,
        'visibleIf': '{employees_drive_vehicles} = true',
        'title': 'Are the vehicles company owned?',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'company_check_mvr',
        'visible': false,
        'visibleIf': '{employees_drive_vehicles} = true',
        'title': 'Does the company check MVRs (motor vehicle reports)?',
        'isRequired': true
      }, {
        'type': 'text',
        'name': 'how_often_check_mvr',
        'visible': false,
        'visibleIf': '{company_check_mvr} = true',
        'title': 'How often does the company check MVRs?',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'discipline_program',
        'visible': false,
        'visibleIf': '{employees_drive_vehicles} = true',
        'title': 'Is there a discipline program in place for transportation / driving violations?',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'vehicle_maintenance_program?',
        'visible': false,
        'visibleIf': '{employees_drive_vehicles} = true',
        'title': 'Is there a vehicle maintenance program?',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'group_transportation',
        'title': 'Does the company use group transportation?',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'osha_300_used',
        'title': 'Is an OSHA 300 Log being used?',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'written_safety_program',
        'title': 'Is a written safety program in place?',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'own,_operate_aircraft_watercraft',
        'title': 'Do you own, operate or lease aircraft or watercraft?',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'use_safety_equipment',
        'title': 'Does the company use safety equipment or PPE?',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'forklifts_utilized',
        'title': 'Are forklifts utilized?',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'forklifts_operators_certified',
        'visible': false,
        'visibleIf': '{forklifts_utilized} = true',
        'title': 'Are forklifts operators certified?',
        'isRequired': true
      }],
      'visible': false,
      'visibleIf': '{industry_type} = \'grey_collar\' or {industry_type} = \'blue_collar\'',
      'title': 'Risk Questions'
    }, {
      'name': 'group_health_questionnaire',
      'elements': [{
        'type': 'html',
        'name': 'please_answer',
        'html': '<p>Please answer the following questions on behalf of your company and indicate a \'yes\' only to questions for which you have specific answers. For example, if you think someone had surgery last year but do not know for what or anything more specific, answer \'no\' to the question.</p>'
      }, {
        'type': 'html',
        'name': 'question1',
        'html': '<p>The information gathered is for actuarial use only and will not be used in connection with any decisions or actions regarding any individual\'s employment.</p>'
      }, {
        'type': 'boolean',
        'name': 'certify_statements_are_true',
        'title': 'I certify that the statements are true and correct to the best of my knowledge and understand that completion of this form does not bind coverage. ',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'any_employees_missed_10_days',
        'visible': false,
        'title': 'Have any employees missed 10 or more consecutive days of work in the past 12 months due to illness or injury?'
      }, {
        'type': 'boolean',
        'name': 'participants_incurred_over_$5,000',
        'title': 'Have any employees, dependents or COBRA participants incurred over $5,000 in claims in the last 12 months?',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'treated_for_a_serious_illness',
        'title': 'Has anyone been treated for a serious illness, been hospitalized or had surgery in the past 5 years?',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'physical_mental_disability',
        'title': 'Is anyone currently hospitalized, confined at home, incapacitated, confined in a treatment facility, or incapable of self-support because of physical or mental disability?',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'advised_medical_treatment_hospitalization_necessary',
        'title': 'Has anyone been advised that medical treatment, diagnostic testing, surgery or hospitalization is necessary?',
        'isRequired': true
      }, {
        'type': 'checkbox',
        'name': 'treatment_for_any_following',
        'title': 'Is any employee or their dependent(s) currently being treated or has been advised to seek treatment for any of the following?',
        'isRequired': true,
        'choices': ['NO CONDITIONS KNOWN', 'AIDS or testing HIV positive', 'Alcohol / drug abuse', 'Arthritis', 'Back disorder', 'Cancer', 'Diabetes', 'Heart disease', 'Kidney disorder', 'Liver disease', 'Mental illness', 'Muscular disorder', 'Nervous system disorders', 'Multiple Sclerosis', 'Respiratory disease', 'Stroke', 'Substance dependence', 'Transplant', 'Tumor'],
        'separateSpecialChoices': true,
        'hasOther': true,
        'colCount': 3
      }, {
        'type': 'boolean',
        'name': 'anyone_disabled',
        'title': 'Are there any employees and/or dependents who are on the health plan that are disabled?',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'currently_pregnant',
        'title': 'Is anyone currently pregnant?',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'anyone_COBRA',
        'title': 'Are there any current COBRA / state continuation participants?',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'participants_currently_eligible_cobra',
        'title': 'Are there any participants currently eligible for COBRA who have not yet elected coverage and/or any participants who will become eligible for COBRA prior to the Health Plan effective date?',
        'isRequired': true
      }, {
        'type': 'boolean',
        'name': 'medical_insurance_dtq',
        'title': 'Has your company received a medical insurance \'decline to quote\' (DTQ) from any carrier or PEO in the past 3 years?',
        'isRequired': true
      }, {
        'type': 'comment',
        'name': 'explain_declination',
        'visible': false,
        'visibleIf': '{medical_insurance_dtq} = true',
        'title': 'Please explain the reason for the declination',
        'isRequired': true
      }, {
        'type': 'paneldynamic',
        'name': 'indicated_member_treatment_condition',
        'visibleIf': '{advised_medical_treatment_hospitalization_necessary} = true or {treated_for_a_serious_illness} = true or {physical_mental_disability} = true or {participants_incurred_over_$5,000} = true or {treatment_for_any_following} anyof [\'Tumor\', \'Transplant\', \'other\', \'Substance dependence\', \'Stroke\', \'Respiratory disease\', \'Multiple Sclerosis\', \'Nervous system disorders\', \'Muscular disorder\', \'Mental illness\', \'Liver disease\', \'Kidney disorder\', \'Heart disease\', \'Diabetes\', \'Cancer\', \'Back disorder\', \'Arthritis\', \'Alcohol / drug abuse\', \'AIDS or testing HIV positive\']',
        'requiredIf': '{advised_medical_treatment_hospitalization_necessary} = true or {treated_for_a_serious_illness} = true or {physical_mental_disability} = true or {participants_incurred_over_$5,000} = true or {treatment_for_any_following} anyof [\'Tumor\', \'Transplant\', \'other\', \'Substance dependence\', \'Stroke\', \'Respiratory disease\', \'Multiple Sclerosis\', \'Nervous system disorders\', \'Muscular disorder\', \'Mental illness\', \'Liver disease\', \'Kidney disorder\', \'Heart disease\', \'Diabetes\', \'Cancer\', \'Back disorder\', \'Arthritis\', \'Alcohol / drug abuse\', \'AIDS or testing HIV positive\']',
        'title': 'You\'ve indicated that a member has received, been advised to receive, or is receiving, treatment for a condition.',
        'description': 'Please answer the following for each Member',
        'templateElements': [{
          'type': 'radiogroup',
          'name': 'gender',
          'visible': false,
          'visibleIf': '{advised_medical_treatment_hospitalization_necessary} = true or {treated_for_a_serious_illness} = true or {physical_mental_disability} = true or {participants_incurred_over_$5,000} = true or {treatment_for_any_following} anyof [\'Tumor\', \'Transplant\', \'other\', \'Substance dependence\', \'Stroke\', \'Respiratory disease\', \'Multiple Sclerosis\', \'Nervous system disorders\', \'Muscular disorder\', \'Mental illness\', \'Liver disease\', \'Kidney disorder\', \'Heart disease\', \'Diabetes\', \'Cancer\', \'Back disorder\', \'Arthritis\', \'Alcohol / drug abuse\', \'AIDS or testing HIV positive\']',
          'title': 'Gender',
          'isRequired': true,
          'choices': ['Male', 'Female']
        }, {
          'type': 'datepicker',
          'inputType': 'date',
          'dateFormat': 'mm/dd/yy',
          'name': 'date_of_birth',
          'visible': false,
          'visibleIf': '{advised_medical_treatment_hospitalization_necessary} = true or {treated_for_a_serious_illness} = true or {physical_mental_disability} = true or {participants_incurred_over_$5,000} = true or {treatment_for_any_following} anyof [\'Tumor\', \'Transplant\', \'other\', \'Substance dependence\', \'Stroke\', \'Respiratory disease\', \'Multiple Sclerosis\', \'Nervous system disorders\', \'Muscular disorder\', \'Mental illness\', \'Liver disease\', \'Kidney disorder\', \'Heart disease\', \'Diabetes\', \'Cancer\', \'Back disorder\', \'Arthritis\', \'Alcohol / drug abuse\', \'AIDS or testing HIV positive\']',
          'title': 'Date of birth',
          'isRequired': true
        }, {
          'type': 'text',
          'name': 'condition',
          'visible': false,
          'visibleIf': '{advised_medical_treatment_hospitalization_necessary} = true or {treated_for_a_serious_illness} = true or {physical_mental_disability} = true or {participants_incurred_over_$5,000} = true or {treatment_for_any_following} anyof [\'Tumor\', \'Transplant\', \'other\', \'Substance dependence\', \'Stroke\', \'Respiratory disease\', \'Multiple Sclerosis\', \'Nervous system disorders\', \'Muscular disorder\', \'Mental illness\', \'Liver disease\', \'Kidney disorder\', \'Heart disease\', \'Diabetes\', \'Cancer\', \'Back disorder\', \'Arthritis\', \'Alcohol / drug abuse\', \'AIDS or testing HIV positive\']',
          'title': 'Condition',
          'description': 'Please indicate the known condition of the member',
          'isRequired': true
        }, {
          'type': 'datepicker',
          'inputType': 'date',
          'dateFormat': 'mm/dd/yy',
          'name': 'onset_of_condition',
          'visible': false,
          'visibleIf': '{advised_medical_treatment_hospitalization_necessary} = true or {treated_for_a_serious_illness} = true or {physical_mental_disability} = true or {participants_incurred_over_$5,000} = true or {treatment_for_any_following} anyof [\'Tumor\', \'Transplant\', \'other\', \'Substance dependence\', \'Stroke\', \'Respiratory disease\', \'Multiple Sclerosis\', \'Nervous system disorders\', \'Muscular disorder\', \'Mental illness\', \'Liver disease\', \'Kidney disorder\', \'Heart disease\', \'Diabetes\', \'Cancer\', \'Back disorder\', \'Arthritis\', \'Alcohol / drug abuse\', \'AIDS or testing HIV positive\']',
          'title': 'Date of onset of condition',
          'isRequired': true
        }, {
          'type': 'boolean',
          'name': 'treatment_ongoing',
          'visible': false,
          'visibleIf': '{advised_medical_treatment_hospitalization_necessary} = true or {treated_for_a_serious_illness} = true or {physical_mental_disability} = true or {participants_incurred_over_$5,000} = true or {treatment_for_any_following} anyof [\'Tumor\', \'Transplant\', \'other\', \'Substance dependence\', \'Stroke\', \'Respiratory disease\', \'Multiple Sclerosis\', \'Nervous system disorders\', \'Muscular disorder\', \'Mental illness\', \'Liver disease\', \'Kidney disorder\', \'Heart disease\', \'Diabetes\', \'Cancer\', \'Back disorder\', \'Arthritis\', \'Alcohol / drug abuse\', \'AIDS or testing HIV positive\']',
          'title': 'Is treatment ongoing?',
          'isRequired': true
        }, {
          'type': 'comment',
          'name': 'prescribed_treatment',
          'visible': false,
          'visibleIf': '{panel.treatment_ongoing} = true',
          'title': 'Prescribed treatment / therapy',
          'isRequired': true
        }, {
          'type': 'comment',
          'name': 'drug_treatment',
          'visible': false,
          'visibleIf': '{panel.treatment_ongoing} = true',
          'title': 'Drug treatment',
          'description': 'Please list all known pharmaceutical treatments prescribed',
          'isRequired': true
        }, {
          'type': 'datepicker',
          'inputType': 'date',
          'dateFormat': 'mm/dd/yy',
          'name': 'last_date_treatment',
          'visible': false,
          'visibleIf': '{panel.treatment_ongoing} = false',
          'title': 'Last date of treatment?',
          'isRequired': true
        }, {
          'type': 'dropdown',
          'name': 'degree_of_recovery',
          'visible': false,
          'visibleIf': '{panel.treatment_ongoing} = false',
          'title': 'Degree of recovery',
          'isRequired': true,
          'choices': ['Recovered', 'Remission'],
          'hasOther': true
        }],
        'panelCount': 1,
        'confirmDelete': true,
        'panelAddText': 'Add new member',
        'panelRemoveText': 'Remove member'
      }, {
        'type': 'paneldynamic',
        'name': 'indicated_someone_is_disabled',
        'visibleIf': '{anyone_disabled} = true',
        'requiredIf': '{anyone_disabled} = true',
        'title': 'You\'ve indicated an employee and/or dependent is currently disabled.',
        'description': 'Please answer the following for each Member',
        'templateElements': [{
          'type': 'text',
          'name': 'name',
          'visible': false,
          'visibleIf': '{anyone_disabled} = true',
          'title': 'Member name',
          'description': 'Please provide the first and last name of the individual ',
          'isRequired': true
        }, {
          'type': 'dropdown',
          'name': 'member_type',
          'visible': false,
          'visibleIf': '{advised_medical_treatment_hospitalization_necessary} = true or {treated_for_a_serious_illness} = true or {physical_mental_disability} = true or {participants_incurred_over_$5,000} = true or {treatment_for_any_following} anyof [\'Tumor\', \'Transplant\', \'other\', \'Substance dependence\', \'Stroke\', \'Respiratory disease\', \'Multiple Sclerosis\', \'Nervous system disorders\', \'Muscular disorder\', \'Mental illness\', \'Liver disease\', \'Kidney disorder\', \'Heart disease\', \'Diabetes\', \'Cancer\', \'Back disorder\', \'Arthritis\', \'Alcohol / drug abuse\', \'AIDS or testing HIV positive\']',
          'title': 'Member type',
          'isRequired': true,
          'choices': ['Employee', 'Dependent'],
          'hasOther': true
        }, {
          'type': 'comment',
          'name': 'disability',
          'visible': false,
          'visibleIf': '{anyone_disabled} = true',
          'title': 'Disability',
          'description': 'Please describe the disability ',
          'isRequired': true
        }, {
          'type': 'text',
          'name': 'qualifying_event',
          'visible': false,
          'visibleIf': '{anyone_disabled} = true',
          'title': 'Qualifying event',
          'isRequired': true,
          'placeHolder': 'Ex: Accident, illness, childbirth'
        }, {
          'type': 'comment',
          'name': 'drug_treatment_disability',
          'visible': false,
          'visibleIf': '{anyone_disabled} = true',
          'title': 'Drug treatment',
          'description': 'Please list all known pharmaceutical treatments prescribed',
          'isRequired': true
        }],
        'panelCount': 1,
        'confirmDelete': true,
        'panelAddText': 'Add new member',
        'panelRemoveText': 'Remove member'
      }, {
        'type': 'paneldynamic',
        'name': 'someone_is_pregnant',
        'visibleIf': '{currently_pregnant} = true',
        'requiredIf': '{currently_pregnant} = true',
        'title': 'You\'ve indicated that someone is pregnant.',
        'description': 'Please provide the following information for each pregnancy:',
        'templateElements': [{
          'type': 'datepicker',
          'inputType': 'date',
          'dateFormat': 'mm/dd/yy',
          'name': 'Due_date',
          'visible': false,
          'visibleIf': '{currently_pregnant} = true',
          'title': 'Member due date',
          'isRequired': true
        }, {
          'type': 'dropdown',
          'name': 'pregnancy_type',
          'visible': false,
          'visibleIf': '{currently_pregnant} = true',
          'title': 'Type of pregnancy or condition',
          'isRequired': true,
          'choices': ['Normal', 'High-risk', 'Preterm labor'],
          'hasOther': true
        },
        {
          "type": "comment",
          "name": "pregnancy_type_description",
          "title": "Please describe the pregnancy or condition",
          "visibleIf": '{panel.pregnancy_type} anyof [\'High-risk\', \'Preterm labor\']',
          "requiredIf": '{panel.pregnancy_type} anyof [\'High-risk\', \'Preterm labor\']'
        }],
        'panelCount': 1,
        'confirmDelete': true,
        'panelAddText': 'Add new member',
        'panelRemoveText': 'Remove member'
      }],
      'visibleIf': '{currently_offer_medical_benefits} = true or {interested_medical_employees_through_peo} = true',
      'title': 'Group Health Questionnaire'
    }, {
      'name': 'employment_practices',
      'elements': [{
        'type': 'boolean',
        'name': 'layoffs_last_12_months',
        'title': 'Have you had any layoffs in the last 12 months?',
        'isRequired': true
      }, {
        'type': 'text',
        'name': 'employees_laid_off',
        'visible': false,
        'visibleIf': '{layoffs_last_12_months} = true',
        'title': 'Please indicate the total number of employees laid off',
        'isRequired': true,
        'inputType': 'number'
      }, {
        'type': 'boolean',
        'name': 'layoffs_next_12_months',
        'title': 'Do you anticipate any layoffs in the next 12 months?',
        'isRequired': true
      }, {
        'type': 'text',
        'name': 'anticipated_layoffs',
        'visibleIf': '{layoffs_next_12_months} = true',
        'title': 'Please indicate the total number of anticipated layoffs',
        'isRequired': true,
        'inputType': 'number'
      }, {
        'type': 'boolean',
        'name': 'any_claims_past_five_years',
        'title': 'Have you had any claims/losses in the past five (5) years for wrongful termination, discrimination, sexual harassment claims, or workplace torts (please include State, Federal, administrative, and EEOC charges)?',
        'isRequired': true
      }, {
        'type': 'text',
        'name': 'total_claim_loss',
        'visibleIf': '{any_claims_past_five_years} = true',
        'title': 'Please indicate total claim/loss',
        'isRequired': true,
        'inputMask': 'currency',
        'prefix': '$'
      }, {
        'type': 'boolean',
        'name': 'aware_claims_against_company',
        'title': 'Are you aware of facts, incidents, or circumstances that may result in claims being made against your company?',
        'isRequired': true
      }, {
        'type': 'comment',
        'name': 'explain_circumstances',
        'visible': false,
        'visibleIf': '{aware_claims_against_company} = true',
        'title': 'Please explain circumstances',
        'isRequired': true
      }],
      'title': 'Employment Practices'
    }, {
      'name': 'signature',
      'elements': [{
        'type': 'datepicker',
        'inputType': 'date',
        'dateFormat': 'mm/dd/yy',
        'name': 'Today\'s date',
        'defaultValue': '{today}',
        'isRequired': true
      }, {
        'type': 'signaturepad',
        'name': 'sign_document',
        'title': 'Sign the document',
        'description': 'Use your mouse or swipe on touch screen  ',
        'isRequired': true,
        'signatureWidth': 700,
        'signatureHeight': 300
      }],
      'title': 'Signature'
    }]
  }
}
