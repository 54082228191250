import { default as Surveyjs } from './surveyjs_controller'
import Rails from "@rails/ujs";


export default class extends Surveyjs {
  connect() {
    this.commonSetup()

    this.survey.mode = 'display'

    this.getData()
  }

  getData() {
    let _this = this
    Rails.ajax({
      url: '/opportunities/' + this.opportunityId + '/questionnaire',
      type: 'get',
      success: (data, status, xhr) => {
        _this.survey.data = data
      }
    })
  }

  async attach() {
    if(!this.hasInputTarget) {
      console.log('Could not attach questionnaire')
      return
    }

    if(this.attached) return
    this.attached = true;

    const blob = await this.downloadBlobPDF()
    const file = new File([blob], `Questionnaire.pdf`);

    let container = new DataTransfer();

    container.items.add(file)
    this.inputTarget.files = container.files
  }

  async downloadBlobPDF() {
    const surveyPDF = super.downloadSetup()

    return await surveyPDF.raw('blob')
  }
}
