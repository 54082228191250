import serializedForms from '../admin/serialized_forms'
import { BaseUrlAction } from './base_url_action_controller'

export default class extends BaseUrlAction {
  static values = {
    modalUrl: String,
    autoOpen: Boolean,
    turboFrameId: String,
    modalStackPosition: { type: Number, default: 0 }
  }

  connect() {
    this.element.classList.add('modal-connected')
    if (this.autoOpenValue === true) {
      this.modalOpenAndLoad()
    }
  }

  urlAction(target) {
    if (this.hasTurboFrameIdValue && this.hasModalUrlValue) { this.modalOpenAndLoad() }
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  modalOpen() {
    $(this.modalContainer).addClass('is-open')
    this.modalContainerController.modalStackPositionValue = document.getElementsByClassName('is-open').length
  }

  modalOpenAndLoad() {
    const frame = document.getElementById(this.turboFrameIdValue)
    const url = new URL(this.modalUrlValue, window.location)
    const params = new URLSearchParams(url.search)
    params.set('refresh', Date.now())
    url.search = params
    this.showLoadingSpinner()
    frame.src = url.toString()
    frame.loaded.then(_success => this.hideLoadingSpinner(), _error => {})
    this.modalOpen()
  }

  hideLoadingSpinner() {
    document.getElementById('loader').classList.add('hidden')
    document.getElementById('side-modal').classList.remove('hidden')
  }

  showLoadingSpinner() {
    if (!$(this.modalContainer).hasClass('is-open')) {
      document.getElementById('loader').classList.remove('hidden')
      document.getElementById('side-modal').classList.add('hidden')
    }
  }

  modalClose() {
    $(this.modalContainer).removeClass('is-open')
    this.modalContainerController.modalStackPositionValue = 0
    const form = $(this.modalContainer).find('form').get(0)
    if (form) {
      form.reset()
      serializedForms.reset(form)
    }
  }

  close() {
    if ($(this.modalContainer).hasClass('is-open')) {
      const form = $(this.modalContainer).find('form').get(0)
      if (serializedForms.handleForm(form)) {
        this.modalClose()
      }
    }
  }

  get modalContainer() {
    return document.getElementById('side-modal-container')
  }

  get modalContainerController() {
    return this.application.getControllerForElementAndIdentifier(this.modalContainer, 'side-modal')
  }
};
