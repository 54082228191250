import {HandsontableBase} from './handsontable_base'
import Handsontable from 'handsontable/dist/handsontable.min.js'
import * as Settings from './settings'

export default class extends HandsontableBase {
  initialize () {
    this.ht = new Handsontable(this.censusTarget, Settings.cobraEmployee(this))
  }

  get resourcesUrl () {
    return `/census/opportunities/${this.opportunityIdValue}/cobra_employees`
  }

  get nextStepUrl () {
    return `/opportunities/${this.opportunityIdValue}/census/cobra_dependents`
  }
};
