import { Controller } from 'stimulus';
export default class extends Controller {
  static get targets () {
    return ["form", "progress"]
  }

  initialize () {
  }

  onError (event) {
    let [data, status, xhr] = event.detail;
    this.formTarget.innerHTML = xhr.response;
  }

  onSuccess (event) {
    let [data, status, xhr] = event.detail;
    this.formTarget.innerHTML = xhr.response;
  }

  showProgress () {
    $(this.progressTarget).css('visibility', 'visible');
  }
};
