import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['expandIcon', 'hiddenContent']
  static values = {
    hiddenClass: { type: String }
  }

  toggle(event) {
    const icon = event.target

    this.hiddenContentTargets.forEach(cell => {
      cell.classList.toggle(this.hiddenClassValue)
    })

    icon.textContent = icon.textContent === 'expand_all' ? 'collapse_all' : 'expand_all'
  }
}
