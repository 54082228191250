import {Controller} from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static get targets () {
    return ['form']
  }

  saveOtherForms (event) {
    this.formTargets.forEach(function(e){
      if($(event.target).closest("form")[0].id !== e.id){
        Rails.fire(e, 'submit')
      }
    })
  }
}
