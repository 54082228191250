import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['firstButton', 'firstInput', 'secondInput', 'secondButton']

  connect() {
    this.setInitialState(this.firstInputTargets, this.firstButtonTargets);
    this.setInitialState(this.secondInputTargets, this.secondButtonTargets);
    this.disableInputsOnButtonChange(this.firstButtonTargets, this.firstInputTargets, this.secondInputTargets);
    this.disableInputsOnButtonChange(this.secondButtonTargets, this.secondInputTargets, this.firstInputTargets);
  }

  disableInputsOnButtonChange(buttons, inputsForTheButton, otherInputs) {
    buttons.forEach((button, index) => {
      button.addEventListener('change', (event) => {
        const isButtonSelected = button.checked
        inputsForTheButton[index].disabled = !isButtonSelected
        otherInputs[index].disabled = isButtonSelected
      })
    })
  }

  setInitialState(inputTargets, buttonTargets) {
    inputTargets.forEach((input, index) => input.disabled = !buttonTargets[index].checked)
  }
}
