import { Controller } from 'stimulus';
export default class extends Controller {
  static get targets () {
    return ["form", "submitButton", "descriptionForm"]
  }

  submit (event) {
    this.submitButtonTarget.click();
  }

  showDescription(event){
    event.target.nextElementSibling.classList.remove('hidden')
    event.target.classList.add('hidden')
  }
};
