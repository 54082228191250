import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'custom', 'list']

  connect() {
    this.reformatContent()
    this.showNecessaryButtons()
  }

  reformatContent() {
    this.updateValue(this.numberToCurrencyTextPercentage(this.getValue()), true)
  }

  numberToCurrencyTextPercentage(value) {
    if (!value || isNaN(value))
      return value

    value = parseFloat(value)

    if (value >= 0.1 && value <= 1)
      return `${Math.floor(value * 100)}%`

    return value.toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0})
  }

  showNecessaryButtons() {
    const allButtons = [...this.customTargets]
    this.showElements(allButtons)

    const currentValue = this.getValue()
    const matchedButtons = allButtons.filter(button => button.innerText === currentValue)
    this.hideElements(matchedButtons)

    const areAllButtonsHidden = allButtons.every(button => button.classList.contains('hidden'))
    this.listTarget.classList.toggle('hidden', areAllButtonsHidden)
  }

  changeToCustom(event) {
    this.updateValue(event.params.custom)
  }

  getValue() {
    return this.inputTarget.type === 'checkbox' ? this.inputTarget.checked.toString() : this.inputTarget.value
  }

  updateValue(value, changeDefault = false) {
    const isCheckbox = this.inputTarget.type === 'checkbox'
    const newValue = isCheckbox ? (value === 'true' || value === '1') : value
    const valueChanged = isCheckbox ? this.inputTarget.checked !== newValue : this.inputTarget.value !== newValue

    if (valueChanged) {
      if (isCheckbox)
        this.inputTarget.checked = newValue
      else
        this.inputTarget.value = newValue

      if (changeDefault)
        this.inputTarget.setAttribute('value', newValue)

      this.inputTarget.dispatchEvent(new InputEvent('input', {bubbles: true}))
    }

    this.showNecessaryButtons()
  }

  showElements(elements) {
    elements.forEach(element => element.classList.remove('hidden'))
  }

  hideElements(elements) {
    elements.forEach(element => element.classList.add('hidden'))
  }
}
