import {HandsontableErrors} from './errors'
import Rails from '@rails/ujs'
import * as Utils from './utils'
import 'handsontable/dist/handsontable.full.css'
import './renderers'
import _forEach from 'lodash/forEach'
import _findIndex from 'lodash/findIndex'
import _isEqual from 'lodash/isEqual'
import _differenceWith from 'lodash/differenceWith'
import _indexOf from "lodash/indexOf";
import {debounce} from 'lodash'

export class HandsontableBase extends HandsontableErrors {
  static targets = ['census', 'status', 'container']
  static values = {
    opportunityId: String,
    censusStatus: String,
    tooltips: Object
  }
  afterChangeAllowedSources = ['edit', 'CopyPaste.paste', 'Autofill.fill']
  afterCreateRowAllowedSources = ['ContextMenu.rowAbove', 'ContextMenu.rowBelow', 'addEmployeeButton']

  callbacklist = []

  validateCells () {
    const _ht = this.ht
    setTimeout(function () {
      _ht.validateCells()
    }, 1000)
  }

  connect () {
    this.loadData()
    this.rowsToDeleteBuffer = [];
  }

  disconnect () {
    this.save()
  }
  initializeWithData(data) { }
  loadData () {
    const _this = this
    Rails.ajax({
      url: _this.resourcesUrl,
      type: 'GET',
      success: (data, _status, _xhr) => {
        _this.initializeWithData(data)
        _this.ht.loadData(data.employees)
        _this.setLastDataLoaded()
        _this.resize()
        _this.loadErrors(data)
        _this.disableReadOnlyCells(data.readOnlyRows)
      },
      error: (_response, _status, xhr) => {
        if(xhr.status === 401){
          window.location = window.location.href
        }
      }
    })
  }

  save () {
    this.includeMissingContent = false
    if (this.ht) {
      Utils.request(
        this.resourcesUrl, 'PATCH',
        JSON.stringify(this.dataDiffForSaving),
        this)
    }
  }

  debouncedSave = debounce(this.save.bind(this), 200)

  nextStep(){
    const _this = this

    this.callbacklist.push(() => {
      _this.includeMissingContent = true
      _this.showErrors()
      if (_this.errorsCount === 0) {
        window.location = _this.nextStepUrl
      }
    })
      this.save()
  }

  forceNextStep() {
    const _this = this

    this.callbacklist.push (() => {
      window.location = _this.nextStepUrl
    })
    this.save()
  }

  afterSaveResponse() {
    _forEach(this.callbacklist, (callback) => {
      callback()
    })
    this.callbacklist = []
  }

  startLoading(){
    if(this.hasStatusTarget){
      this.statusTargets.forEach((statusTarget) => {
        statusTarget.innerHTML = 'Loading...'
      })
    }
  }

  disableButtons() {
    if (this.hasContainerTarget) {
      this.containerTarget.classList.add('request-in-progress')
    }
  }

  disableReadOnlyCells(readOnlyRows) {
    if (readOnlyRows) {
      readOnlyRows.forEach((row) => {
        for (let col = 0; col < this.ht.countCols(); col++) {
          this.ht.setCellMeta(row, col, 'readOnly', true)
          this.ht.setCellMeta(row, col, 'className', 'read-only-cell htMiddle')
          this.ht.setCellMeta(row, col, 'type', 'text')
        }
      })
    }
  }

  enableButtons() {
    if (this.hasContainerTarget) {
      this.containerTarget.classList.remove('request-in-progress')
    }
  }

  finishLoading(){
    const time = new Date()
    if(this.hasStatusTarget){
      this.statusTargets.forEach((statusTarget) => {
        statusTarget.innerHTML = `Last Saved: ${time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`
      })
    }
  }

  deleteRows(index, amount) {
    const rowsToRemove = this.rowsForRemoval(index, amount);
    this.rowsToDeleteBuffer.push(...rowsToRemove);
    this.debouncedBatchDeleteRows();
  }

  debouncedBatchDeleteRows = debounce(this.batchDeleteRows.bind(this), 200);

  batchDeleteRows() {
    if (this.rowsToDeleteBuffer.length > 0) {
      const rowsToDeleteNow = [...this.rowsToDeleteBuffer];
      this.rowsToDeleteBuffer = [];

      Utils.request(
        this.resourcesUrl,
        'DELETE',
        JSON.stringify(rowsToDeleteNow),
        this
      );
    }
  }

  rowsForRemoval (index, amount) {
    return this.ht.getSourceData(index, 'id', index + amount - 1, 'id')
  }

  get dataDiffForSaving () {
    let fullData = this.ht.getSourceData()
    Utils.updatePositions(fullData)
    let diff = _differenceWith(fullData, this.lastSavedData, _isEqual)
    _forEach(diff, (row, index) => { diff[index].position = _findIndex(fullData, row) })
    return diff
  }

  setLastDataLoaded () {
    this.lastSavedData = this.ht.getSourceData()
  }

  populateMetaData(data){
    if(data.employees.length > 0){
      this.ht.populateFromArray(0, 0, data.employees)
    }
  }

  resize(_event){
    const windowHeight = $(window.top).height()
    const spreadsheetTop = $(this.censusTarget).position().top
    const footerHeight = 120;
    const spreadsheetHeight = (windowHeight - spreadsheetTop - footerHeight)
    this.ht.updateSettings({height: spreadsheetHeight})
  }

  removeEmployee(event){
    this.ht.alter('remove_row',
      _indexOf(this.ht.getDataAtProp('id'),
        parseInt(event.target.dataset.employeeId))
    )
  }

  addEmployeeBelow(event){
    this.ht.alter('insert_row_below',
      parseInt(event.target.dataset.rowId), 1, 'addEmployeeButton')
  }

  tooltip(key) {
    return this.tooltipsValue[key]
  }
}
