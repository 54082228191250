$(window).bind('turbo:load', function() {
  (function () {

    const el = document.querySelector('.js-toggle');
    if (!el) return;

    document.addEventListener('click', function (event) {
      if (!event.target.classList.contains('js-toggle')) return;
      event.preventDefault();

      let target = event.target;

      let els = target.closest('.js-tabs').querySelectorAll('.js-toggle');

      for (let el of els) {
        el.classList.remove('is-active');
        let element = document.querySelector(el.hash);
        if (element) {
          element.classList.remove('is-active');
        }
      }
      target.classList.toggle('is-active');

      // Get the content
      let content = document.querySelector(target.hash);
      if (!content) return;

      // Toggle the content
      content.classList.toggle('is-active');
    }, false);

  })();
})
