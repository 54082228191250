import { Controller } from 'stimulus';
export default class extends Controller {
  connect () {
    $(this.element).collapsible({
      onOpenStart: function(el) {
        if($(el).data()){
          window.location.hash = $(el).data().idx;
        }
        updateFixedHeaderTable();
      },
      onCloseEnd: function (el) {
        updateFixedHeaderTable();
      }
    });

    if(window.location.hash){
      var elem = $('.collapsible > li[data-idx='+ window.location.hash.substring(1) +']');
      elem.addClass('active');
      elem.children('.collapsible-body').css({display: 'block'});
    }
  }
};
