import {Controller} from 'stimulus'
import Dropzone from 'dropzone'
import 'dropzone/dist/dropzone.css'

export default class extends Controller {
  static get targets () {
    return ['form', 'dropzone', 'results']
  }

  static values = {
    opportunityId: Number,
    peoFolderId: Number,
    url: String
  }

  connect () {
    let controller = this

    let myDropzone = new Dropzone(this.dropzoneTarget, {
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        'Accept': 'text/vnd.turbo-stream.html'
      },
      addRemoveLinks: true,
      acceptedFiles: '.gif,.jpg,.jpeg,.png,.pdf,.xls,.xlsx,.doc,.docx,.txt',
      dictDefaultMessage: 'Drop files or click here to upload. <br /> Files will be automatically uploaded to the server',
      uploadMultiple: true
    })

    myDropzone.on('complete', function (file, response) {
      const frame = controller.resultsTarget
      const _this = controller
      frame.loaded.then(function (success) {
        frame.src = _this.urlValue.replace(/NUMBER/g, Date.now())
      }, function (error) {})
    })
  }
};
