import {Controller} from 'stimulus'

export default class extends Controller {

  static targets = ['sourceField', 'planField']

  fillRow = (event) => {
    const value = event.target.value

    this.planFieldTargets.forEach((field) => {
      if (field.value === '') {
        field.value = value
        field.dispatchEvent(new Event('input', { bubbles: true }))
      }
    })
  }
}
