export default {
  OBSERVER: null,
  MESSAGE: 'All unsaved changes will be lost, do you want to continue?',
  CHANGED_FORMS: new Set(),

  set(form) {
    this.CHANGED_FORMS.add(form)
  },
  reset(form) {
    this.CHANGED_FORMS.delete(form)
  },
  resetAll() {
    this.CHANGED_FORMS.clear()
  },
  observeFormCreation() {
    const getDescendantsAndSelf = (node, accum = [node]) => {
      for (const child of node.childNodes) {
        accum.push(child)
        getDescendantsAndSelf(child, accum)
      }
      return accum
    }

    const guardedFormNodes = node => getDescendantsAndSelf(node)
      .filter(node => (node instanceof Element && node.tagName.toLowerCase() === 'form'))
      .filter(formNode => formNode.dataset.formGuard !== 'false')

    const callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        for (const node of mutation.addedNodes) {
          guardedFormNodes(node).forEach(formNode => formNode.addEventListener('input', this.set.bind(this, formNode)))
        }
        for (const node of mutation.removedNodes) {
          guardedFormNodes(node).forEach(formNode => this.reset(formNode))
        }
      }
    }
    this.OBSERVER = new MutationObserver(callback)

    this.OBSERVER.observe(document.documentElement, {childList: true, subtree: true})
  },
  confirmAndReset(event) {
    if (!confirm(this.MESSAGE)) {
      if (event) {
        event.preventDefault()
      }
      return false
    }
    this.resetAll()
    return true
  },
  confirmAndResetForm(form) {
    if (!confirm(this.MESSAGE)) {
      return false
    }
    this.reset(form)
    return true
  },

  handleEvent(event) {
    if (this.CHANGED_FORMS.size === 0) {
      return true
    }

    return this.confirmAndReset(event)
  },

  handleForm(form) {
    if (this.CHANGED_FORMS.has(form)) {
      return this.confirmAndResetForm(form)
    }
    return true
  },

  handleBeforeUnload(event) {
    if (this.CHANGED_FORMS.size > 0) {
      event.preventDefault()
      return (event.returnValue = this.MESSAGE)
    }
  },
  preventLeavingUnsavedForms() {
    document.documentElement.addEventListener('turbo:before-visit', this.handleEvent.bind(this))
    document.documentElement.addEventListener('turbo:before-fetch-request', (event) => {
      if (event.detail.url.searchParams.get('ignore_guard') === 'true')
        return

      if (event.target instanceof Element && event.target.tagName.toLowerCase() === 'form' && event.detail.fetchOptions.method.toLowerCase() === 'post') {
        //reset and return if form submit
        this.resetAll()
        return
      }
      this.handleEvent(event)
    })
    addEventListener('submit', this.resetAll.bind(this))
    addEventListener('beforeunload', this.handleBeforeUnload.bind(this))
  }
}
