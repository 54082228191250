import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['sidebar']

  initialize () {
    this.setSidebarClasses()
  }

  toggle() {
    this.toggleState()
    this.setSidebarClasses()
  }

  setSidebarClasses(){
    if(this.getState){
      this.element.classList.add('slim-portal-sidenav')
    }else{
      this.element.classList.remove('slim-portal-sidenav')
    }
  }

  get getState(){
    return localStorage.getItem('portal-sidebar') === 'true'
  }

  toggleState(){
    if(this.getState){
      localStorage.setItem('portal-sidebar', 'false')
    }else{
      localStorage.setItem('portal-sidebar', 'true')
    }
  }


}
