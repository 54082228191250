import {Controller} from 'stimulus'

export default class extends Controller {
  sync(e) {
    const value = e.target.value
    const key = e.target.getAttribute('data-fields-sync-key')
    this.element.querySelectorAll(`[data-fields-sync-key="${key}"]`).forEach((el) => {
      el.value = e.target.value
    })
  }
}
