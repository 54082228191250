import {HandsontableBase} from './handsontable_base'
import Handsontable from 'handsontable/dist/handsontable.min.js'
import * as Settings from './settings'

export default class extends HandsontableBase {
  initializeWithData (data) {
    this.ht = new Handsontable(this.censusTarget, Settings.workEmployee(this, undefined, data['questionnaire_entities']))
  }

  get resourcesUrl () {
    return `/census/opportunities/${this.opportunityIdValue}/work_employees`
  }

  get nextStepUrl () {
    return `/opportunities/${this.opportunityIdValue}/census/dependents`
  }
};
