import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['content', 'list']

  // syncs active tab with comparison form when comparisons are updated via turbo streams
  listTargetConnected(listElement) {
    let activeTab = listElement.querySelector('.js-toggle.is-active')
    this.syncTabsWithContent(activeTab);
  }

// syncs active tab with comparison form after comparisons are lazily loaded
  contentTargetConnected(contentElement) {
    let activeTab = this.listTarget.querySelector('.js-toggle.is-active')
    this.syncTabsWithContent(activeTab);
  }

  syncTabsWithContent(activeTab) {
    if (this.hasContentTarget && activeTab) {
      this.contentTargets.forEach((content, index) => {
        content.classList.remove('is-active')
      })
      let activeContent = document.querySelector(activeTab.hash)
      if (activeContent) {
        activeContent.classList.add('is-active');
      }
    }
  }
};
