import { Controller } from 'stimulus';
export default class extends Controller {
  static get targets () {
    return ["form", "newNoteId", "submitButton", "toggleNoteId"]
  }

  initialize(){
    let submitButton = this.submitButtonTarget;
    // require([ "jquery", "jquery-ui/ui/widgets/sortable" ], function( $ ) {
    $('ul.collection').sortable({
      handle: '.secondary-content',
      stop: function (event, ui) {
        $('ul.collection li').each(function (idx, el) {
          $(el).find('.position').val(idx)
        })
        submitButton.click()
      }
    })
    // });
  }

  showProgress(element){
    element.innerHTML =
      '<svg class=\'animate-spin\' height=\'17\' width=\'17\'>' +
      '<use xlink:href=\'#ico-animate-spin\'/>' +
      '</svg>'
  }

  toggleNote(event){
    this.toggleNoteIdTarget.value = event.currentTarget.getAttribute('data-toggleNoteId');
    this.submitButtonTarget.click();
  }

  setNewNoteId(event){
    this.newNoteIdTarget.value = event.currentTarget.getAttribute('data-parentNoteId');
    this.submitButtonTarget.click();
  }

  confirmDelete(event){
    if(confirm('Are you sure?')){
      this.showProgress(event.currentTarget);
      return true;
    } else {
      event.preventDefault()
      return false;
    }
  }

  submit(){
    this.submitButtonTarget.click();
  }
};
