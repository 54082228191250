import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['confirm']

  connect() {
    this.element.addEventListener('submit', this.submit.bind(this));
  }

  getInputValue(inputElement) {
    switch (inputElement.type) {
      case 'checkbox':
        return inputElement.checked;
      case 'radio':
        return inputElement.checked;
      default:
        return inputElement.value;
    }
  }

  submit() {
    this.confirmTargets.forEach((target) => {
      const value = this.getInputValue(target);
      const confirmValue = target.dataset.confirmValue;

      if (value.toString() !== confirmValue.toString())
        return;

      const confirmMessage = target.dataset.confirmValueMessage;

      if(confirm(confirmMessage) === false)
        event.preventDefault();
    });
  }
}
