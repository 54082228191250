import {Controller} from 'stimulus'
import Inputmask from "inputmask";

export default class extends Controller {
  static targets = ['input']

  connect() {
    const input = this.inputTarget

    Inputmask(
      'decimal',
      {
        suffix: '%',
        rightAlign: false,
        removeMaskOnSubmit: true,
        showMaskOnHover: false,
        digits: 1,
        onBeforeMask: (value, _opts) => value * 100,
        onUnMask: (_maskedValue, unmaskedValue) => unmaskedValue / 100,
      },
    ).mask(input)
  }
}
