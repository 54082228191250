import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['submit']

  connect() {
    if (this.hasSubmitTarget) this.submitTarget.addEventListener('click', event => event.stopPropagation())
  }

  submitOnSelect(event) {
    if (this.hasSubmitTarget) {
      this.submitTarget.click()
      this.submitTarget.dispatchEvent(new InputEvent('input', {bubbles: true}))
    }
  }
}
