import forms_original_data from './forms_original_data'
import './quill.global'
import './global'
import './custom_trix_editor'
import 'jquery-ui'

$( document ).ready(function() {
  //TODO: make sure if works when going back/forward in the browser
  $.each($('form'), function(i, v){
    forms_original_data.FORMS[v.id] = $(v).serialize();
  });
});

$(window).bind('turbo:load', function() {
  M.updateTextFields();

  $('a.submit').on('click', function() {
    if(isAnyRequiredFieldEmpty()){
      if(confirm("Some of the required fields are empty. Do you want to continue?")){
        window.onbeforeunload = null;
        $('form:visible').submit();
      }
    }else{
      window.onbeforeunload = null;
      $('form:visible').submit();
    }
    return false;
  });

  $('ul.collection').sortable({
    handle: '.secondary-content',
    stop: function (event, ui) {
      $('ul.collection li').each(function (idx, el) {
        $(el).find('.position').val(idx)
      })
    }
  })

  if ($('.ql-editor').length <= 0){
    Quilljs.loadDefaults();
  }
});
