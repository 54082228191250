export default class BenefitComparison{
  constructor(url) {
    this.url = url;
  }

  update_plan(sel) {
    var planId = $(sel).val();
    var columnIdx = $(sel).data('idx');
    var resetBtn = $(sel).siblings('button.plan-reset');
    $.ajax({
      url: '/' + this.url + '/' + planId,
      dataType: 'json',
      method: 'GET',
      success: function (data) {
        if (resetBtn.data('plan') != planId) {
          resetBtn.removeClass('hide');
        } else {
          resetBtn.addClass('hide');
        }
        $.each(data, function (k, v) {
          var field = $('[data-field="' + k + '"][data-idx="' + columnIdx + '"]:visible');
          field.html(v);
          field.val(v);
          if (k === 'referrals_required' && v === 'Yes') {
            field.prop('checked', true);
          } else {
            field.prop('checked', false);
          }
        });
      }
    })
  }
}

$(window).bind('turbo:load', function() {
  $(document).on('change', '.plan-change:visible', function () {
    //update_plan(this);
  });

  $('button.plan-reset').on('click', function () {
    var select = $(this).siblings('select.plan-change');
    var planId = $(this).data('plan');
    select.val(planId);
    select.trigger('change');
    return false;
  });

  $(window).bind('turbo:before-cache', function () {
    $('select.plan-change').off('change');
    $('button.plan-reset').off('click');
  });

});
