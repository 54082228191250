import {HandsontableBase} from './handsontable_base'
import * as Utils from './utils'
import _indexOf from 'lodash/indexOf'
import _forEach from 'lodash/forEach'

export class HandsontableDependents extends HandsontableBase {
  addDependent(event) {
    const lastRowId = this.ht.getDataAtProp('employee_id').findLastIndex((employee_id) => employee_id === parseInt(event.target.dataset.employeeId))
    this.ht.batch(() => {
      this.ht.alter('insert_row_below', lastRowId, 1, 'addDependent')
      this.ht.setSourceDataAtCell(lastRowId + 1, 'parent_position', parseInt(event.target.dataset.parentPosition))
      this.ht.setSourceDataAtCell(lastRowId + 1, 'employee_id', parseInt(event.target.dataset.employeeId))

      this.debouncedSave()
      this.ht.render()
    })
    return false
  }

  populateMetaData(data) {
    super.populateMetaData(data)
    this.ht.batch(() => {
      data.actionCells.forEach((cell, index) => {
        this.ht.setSourceDataAtCell(index, 'action', cell)
      })
    })
  }

  removeDependent(event) {
    this.ht.alter('remove_row',
      _indexOf(this.ht.getDataAtProp('id'),
        parseInt(event.target.dataset.dependentId))
    )
  }
}
