import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener('keydown', this.handleKeyDown);
  }

  disconnect() {
    this.element.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }
}