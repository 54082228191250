import Handsontable from 'handsontable/dist/handsontable.min.js'

Handsontable.validators.registerValidator('oa.validator.zip-code', (value, callback) => {
  callback(/^\d{5}(?:[-\s]\d{4})?$/.test(value))
})

function workEmployeeFullNameRenderer(instance, td, row, col, prop, value, cellProperties){
  const employeeId = instance.getDataAtRowProp(row, 'id')
  const opportunityId = instance.getDataAtRowProp(row, 'opportunity_id')
  const prospectId = instance.getDataAtRowProp(row, 'prospect_id')

  td.classList.add('htMiddle')
  td.innerHTML = `<div class="work-employee">
    <span>${value}</span>
    <a
        data-controller="side-modal"
        data-side-modal-modal-id-value="#modal-edit-employee"
        data-side-modal-turbo-frame-id-value="side-modal"
        data-action="side-modal#modalOpenAndLoad"
        data-side-modal-modal-url-value="/prospects/${prospectId}/opportunities/${opportunityId}/employees/${employeeId}/edit"
    >Edit Details</a>
    </div>`
}
Handsontable.renderers.registerRenderer('oa.renderer.work-employee-full-name', workEmployeeFullNameRenderer)
