/*
This controller will be replaced by the new one `scroll-comments` when the new version of comments
will be used for the whole site.
 */

import {Controller} from 'stimulus'

export default class extends Controller {
  connect(){
    this.scroll()
  }

  scroll () {
    let tab = this.element.closest('.scrollable__box')
    tab.scrollTop = tab.scrollHeight
  }
}
