require('slim-select')
import 'slim-select/styles'
import {Controller} from 'stimulus'
import SlimSelect from 'slim-select'

export default class extends Controller {
  static values = {
    placeholder: String
  }

  connect () {
    this.slimselect = new SlimSelect({
      select: this.element,
      settings: {
        maxValuesShown: 100,
        closeOnSelect: false,
        placeholderText: this.hasPlaceholderValue ? this.placeholderValue : null
      },
      events: {
        searchFilter: (option, search) => {
          let searchable = option.data.searchable || ''
          return option.text.toLowerCase().indexOf(search.toLowerCase()) !== -1 || searchable.toLowerCase().indexOf(search.toLowerCase()) !== -1
        },
        afterChange: (selectedOptions) => {
          selectedOptions.forEach((option) => this.colorizeOptionBackground(option))
        }
      }
    })
    this.colorizeOptionsAfterRender();

  }

  colorizeOptionsAfterRender() {
    const selectedValuesIds = this.slimselect.getSelected()
    const selectedOptions = this.slimselect.getData().map((optgroup) => optgroup.options.filter((option) => selectedValuesIds.includes(option.value))).flat()
    selectedOptions.forEach((option) => this.colorizeOptionBackground(option))
  }

  colorizeOptionBackground(option) {
    const color = option.data.backgroundColor
    const id = option.id
    const divElement = document.querySelector(`div.ss-value[data-id="${id}"]`);
    divElement.style.backgroundColor = color
  }

  disconnect () {
    this.slimselect.destroy()
  }
}
