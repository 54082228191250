import {Controller} from 'stimulus'

export class BaseUrlAction extends Controller {
  static targets = ['urlAction']

  urlAction(target) {
  }

  urlActionTargetConnected(target) {
    const attribute = target.getAttribute('data-url-action-value')
    if(!attribute)
      return
    const params = new URLSearchParams(window.location.search)
    if(attribute === params.get('url_action'))
      this.urlAction(target)
  }
}
