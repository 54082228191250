import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["subject", "participants", "subjectContainer", "isGroup"]

  connect() {
    this.toggleSubject()
  }

  toggleSubject() {
    if (this.hasParticipantsTarget && this.hasIsGroupTarget) {
      const isEnabled = this.participantsTarget.selectedOptions.length >= 2 || this.isGroupTarget.value === "true";
      this.subjectTarget.disabled = !isEnabled;
      this.subjectContainerTarget.style.display = isEnabled ? "flex" : "none";
    }
  }
}
