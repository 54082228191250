import { Controller } from 'stimulus'
import Dropzone from 'dropzone'
import { post } from '@rails/request.js'

import 'dropzone/dist/dropzone.css'

export default class extends Controller {
  static targets = ['form', 'dropzone', 'uploadedFiles', 'statusIndicator']
  static values = {
    requests: Array,
    handler: String,
    handlerParams: Object
  }

  connect() {
    const myDropzone = new Dropzone(this.dropzoneTarget, {
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      addRemoveLinks: true,
      acceptedFiles: '.gif,.jpg,.jpeg,.png,.pdf,.xls,.xlsx,.doc,.docx,.txt,.csv',
      dictDefaultMessage: 'Drop files or click here to upload. <br /> Files will be automatically uploaded to the server'
    })

    const customHandlers = {
      addUserUploadedTableRow: {
        event: 'complete',
        handler: file => {
          const response = JSON.parse(file.xhr.response)
          post(this.handlerParamsValue['append_document_row_path']
            .replaceAll('opportunity_id_placeholder', response.opportunity_id)
            .replaceAll('client_document_id_placeholder', response.client_document_id),
          { responseKind: 'turbo-stream' })
        }
      }
    }

    myDropzone.on('complete', this.updateTurboFrames.bind(this))

    if (this.hasHandlerValue) {
      const customHandler = customHandlers[this.handlerValue]
      myDropzone.on(customHandler.event, customHandler.handler)
    }
  }

  updateTurboFrames() {
    this.requestsValue.forEach(url => {
      post(url, { responseKind: 'turbo-stream' })
    })
  }
}
