import Flatpickr from 'stimulus-flatpickr'

import {english} from 'flatpickr/dist/l10n/default.js'

import 'flatpickr/dist/themes/light.css'


export default class extends Flatpickr {
  static values = {disableUntil: String}

  initialize () {
    this.config = this._config
    this.element.autocomplete = 'off'
  }

  open(){
    // HACK for Materialize overwriting styles of Month selector
    $(".flatpickr-calendar .select-wrapper select").each(function(i, e){
      let select = $(e);
      let selectWrapper = select.parent();
      select.detach().prependTo(selectWrapper.parent())
      selectWrapper.hide();
    });
    $(".flatpickr-month input ").each(function(i, e){
      $(e).addClass('browser-default')
    });
  }

  get _config () {
    return {
      locale: english,
      allowInput: true,
      enableTime: false,
      altInput: false,
      dateFormat: 'm/d/Y',
      disable: this.hasDisableUntilValue ? [(date) => date < new Date(this.disableUntilValue.replaceAll('"',''))] : []
    }
  }
}
