import {Controller} from 'stimulus'

export default class extends Controller {
  connect () {
    let _this = this
    $(this.element).keyup(function(event){
      const keycode = (event.keyCode ? event.keyCode : event.which);
      if(keycode === 13){
        _this.element.editor.insertLineBreak()
      }
    });
  }
};
