import './main/__detects'
import './main/_toggle'

import '../src/quill.global'
import '../src/global'

import serializedForms from '../admin/serialized_forms'

// const serializedForm = new serializedForms(document.documentElement)
serializedForms.observeFormCreation()
serializedForms.preventLeavingUnsavedForms()
