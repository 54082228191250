import {Controller} from 'stimulus'

export default class extends Controller {
  static values = {
    message: String,
    parameters: Object
  }
  connect() {
    if(this.hasParametersValue)
      this.element.addEventListener('submit', this.submitEvent.bind(this), { capture: true })
  }
  submitEvent(event) {
    if(!confirm(this.message()))
      return

    for (const key in this.parametersValue) {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = key
      input.value = this.parametersValue[key]
      this.element.appendChild(input)
    }
  }

  message() {
    if(this.hasMessageValue)
      return this.messageValue
    return `Do you want to include ${JSON.stringify(this.parametersValue)} in the request?`
  }
}
